.context-menu {
    position: fixed;
    border: 1px solid black;
    background-color: white
}

.context-menu .context-menu__item {
    padding: 4px;
}

.context-menu .context-menu__item:not(:first-child) {
    border-top: 1px solid black;
}

.context-menu .context-menu__item:hover {
    background-color: lightblue;
}
    
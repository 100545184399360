@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
    --sustainability-black: #000000;
    --sustainability-white: #ffffff;
    --sustainability-primary_btn: #1B3548;
    --sustainability-primary_btn-hover: #1B3548;
    --sustainability-primary: #1B3548;
    --sustainability-light: #1B3548;
    --sustainability-xtralight: rgba(27, 53, 72, 0.033);
    --sustainability-dark: #DE4724;
    --sustainability-bs-accordion-active-bg: var(--white);
    --sustainability-chartbg: #F9F9F9;
    --sustainability-link: #1B3548;
}

img {
    max-width: 100%;
    height: auto;
}


html,
body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f9f9f9;
    box-sizing: border-box;
}

@font-face {
    font-family: "Homemade Apple";
    src: local('Homemade Apple'), url('./static/fonts/HomemadeApple-Regular.ttf') format("truetype");
}

@font-face {
    font-family: "Caveat";
    src: local('Caveat'), url('./static/fonts/Caveat-Regular.ttf') format("truetype");
}

@font-face {
    font-family: "Dancing Script";
    src: local('Dancing Script'), url('./static/fonts/DancingScript-Regular.ttf') format("truetype");
}

@font-face {
    font-family: "Permanent Marker";
    src: local('Permanent Marker'), url('./static/fonts/PermanentMarker-Regular.ttf') format("truetype");
}

@font-face {
    font-family: "Rock Salt";
    src: local('Rock Salt'), url('./static/fonts/RockSalt-Regular.ttf') format("truetype");
}

.doc-viewer button.focus\:outline-none {
    display: none;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: #66CFA4 !important;
}

.holder-wrp h6 {
    font-size: 18px;
    font-family: poppins;
    margin-bottom: 0px;
    margin-top: 20px;
}

.holder-wrp a {
    font-size: 14px;
    font-family: poppins;
    margin-bottom: 0px;
    margin-top: 20px;
}

.holder-wrp a h5 {
    margin-top: 10px;
    margin-bottom: 20px;
}

.border-top-line {
    margin-top: 30px !important;
}

.borderTop {
    background: #ddd;
    width: 1060px;
    height: 2px;
    position: absolute;
    top: -30px;
}

.input-owner-wrp h6 {
    font-size: 18px;
    color: #000;
    font-family: poppins;
    margin-top: 30px;
    margin-bottom: 10px;
}

.input-owner-wrp .flex.items-start {
    position: relative;
}

.input-owner-wrp {
    width: 300px;
}

.input-owner-wrp input {
    height: 40px;
    border: 1px solid #c6c6c6;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 500;
    width: 300px;
}

.input-owner-wrp button {
    position: absolute;
    top: 1px;
    right: -21px;
    height: 50px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #66CFA4;
    border: unset;
    border-radius: 3px;
}

.margin-top-25 {
    margin-top: 25px !important;
}

@media screen and (min-device-width: 1280px) and (max-device-width: 1440px) {
    .borderTop {
        width: 1060px;
    }
}

@media screen and (min-device-width: 1600px) and (max-device-width: 2200px) {
    .borderTop {
        width: 1230px;
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

#surrender-sign {
    color: red;
    font-family: poppins;
    margin-bottom: 0px;
    font-size: 12px;
}

.center_div {
    justify-content: center !important;
    margin: 20px 0px !important;
}

.pdfbar-btngroup {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pdfbar-btngroup button {
    padding: 12px 30px;
    border-radius: 10px;
    border: none;
    background: #1b3648;
    color: #ffffff;
    border: 1px solid #1b3648;
    transition: .3s ease;
    cursor: pointer;
}

.pdfbar-btngroup button:hover {
    background: transparent;
    color: #1b3648;
}

.pdfbar-btngroup button:disabled {
    opacity: 50%;
    cursor: not-allowed;
    pointer-events: none;
}

button.transition-colors.duration-200 .animate-spin {
    font-size: 12px !important;
    height: 10px !important;
    width: 10px !important;
}

/* PDf Booklet css  */
.stf__item .react-pdf__Page {
    width: 100% !important;
    height: 100%;
}

.stf__item .react-pdf__Page canvas {
    width: 100% !important;
    height: 100% !important;
}

.react-pdf__Document {
    overflow: hidden;
}


.upl-btn {
    border-radius: 15px !important;
    height: 48px !important;
    padding: 10px 25px !important;
    color: #fff !important;
    font-weight: 600 !important;
    letter-spacing: 0.8px !important;
    box-shadow: unset !important;
    margin-top: 15px !important;
    font-size: 16px !important;
}

.fOne-doc-text {
    color: #7E8D90 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    letter-spacing: 0.5px !important;
    margin-top: -25px !important;
}

.upl-wrp-style {
    border: 2px solid #7E8D90;
    padding: 75px !important;
    border-radius: 10px !important;
}

.upl-icon-list svg {
    height: 40px !important;
    width: 40px !important;
}

.upl-step-label .MuiStepLabel-label {
    font-size: 16px !important;
    font-weight: 600 !important;
}

.upl-step-label .MuiStepIcon-text {
    color: #fff !important;
    fill: #fff;
    font-weight: 600;
    font-size: 14px !important;
}

.fileupl-title {
    font-size: 16px !important;
    font-weight: 500 !important;
}

.next-upl-btn {
    background: #66CFA4 !important;
    color: #fff !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    padding: 5px 20px !important;
}

.next-upl-btn.Mui-disabled {
    cursor: not-allowed !important;
}

.back-upl-btn {
    background: #ddd !important;
    color: #535353 !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    padding: 5px 20px !important;
    box-shadow: unset !important;
}

.upl-select-box {
    background: transparent;
    height: 50px;
    border-radius: 30px;
    padding: 15px;
    border: 1px solid #7E8D90 !important;
    width: 250px;
    font-size: 14px;
    color: #7E8D90 !important;
    box-shadow: unset !important;
}

.upl-select-box::before,
.upl-select-box::after {
    display: none !important;
}

.upl-select-box option {
    background: #fff !important;
    color: #333 !important;
}

.upl-select-box option:hover {
    background: #eee !important;
}

.makeStyles-paper3-70.select-seg-table {
    margin: 25px 0px !important;
    background: transparent !important;
    border: 1px solid #7E8D90 !important;
    padding: 0px 25px !important;
    box-shadow: unset !important;
    border-radius: 15px !important;
}

.makeStyles-paper3-70.select-seg-table thead th {
    background: transparent !important;
    border-bottom: 1px solid #7E8D90;
    padding: 10px;
    position: relative !important;
}

.select-seg-table table.MUIDataTableFooter-root-175 {
    display: none !important;
}

.makeStyles-paper3-70.select-seg-table td {
    padding: 10px !important;
}

.makeStyles-paper3-70.select-seg-table thead th span {
    font-size: 16px !important;
    text-transform: capitalize !important;
}

.select-seg-table .MuiToolbar-gutters {
    display: none;
}

.makeStyles-paper3-70.select-seg-table>* {
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: unset !important;
    background: transparent !important;
    height: auto !important;
}

.doc-sign-com-wrp {
    background: transparent !important;
}

.doc-sign-com-wrp .makeStyles-heading-365 {
    margin-bottom: 0px;
}

.doc-sign-com-wrp .makeStyles-subHeading-66 {
    margin-bottom: 60px;
    margin-left: -160px !important;
}

.paddingTop30 {
    padding-top: 30px !important;
}

.modalTitleFlex .MuiTypography-h6 {
    display: flex;
    justify-content: end;
}

.titleRed .MuiButton-label {
    color: red;
}

.titleGreen .MuiButton-label {
    color: green;
}

.badge {
    background: #eee;
    font-size: 12px;
    border-radius: 50px;
    padding: 5px 10px;
}

.badge-success {
    background: #66CFA4 !important;
    color: #fff;
}

.my-2 {
    margin-left: 2px;
    margin-right: 2px;
}

.document-detail-card .MuiAppBar-root {
    margin-top: 25px;
}

.document-detail-card .MuiCardContent-root {
    padding: 0 !important;
}

.users-list-item .MuiAvatar-root {
    margin: 0;
    width: 50px;
    height: 50px;
    font-size: 20px;
}

.users-list-item .user-info {
    text-align: left;
    padding-left: 10px;
    width: 100%;
}

.users-list-item .user-info h6 {
    font-size: 15px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.users-list-item .user-info p {
    font-size: 13px;
    margin-top: -5px;
}

.users-list-item .user-actions {
    display: flex;
    align-items: center;
}

.user-actions .current-owner {
    color: #fff;
    background: #66CFA4;
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 50px;
    font-weight: 600;
}

.document-detail-card .MuiCardContent-root .MuiTabPanel-root {
    padding: 0 !important;
}

.users-list-item {
    display: flex;
    padding: 15px 10px;
    align-items: center;
    border-bottom: 1px solid #dedede;
}

.users-list-item .MuiButtonBase-root {
    min-width: unset;
}

.users-list-item.add-new {
    background: #eee;
    text-align: center;
    padding: 0;
}

.users-list-item.add-new p.MuiTypography-root {
    font-size: 14px;
    width: 100%;
    text-align: center;
    padding: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.users-list-item.not-found p {
    text-align: center;
    width: 100%;
    display: block;
    font-size: 14px;
}

.users-list-item.add-new p.MuiTypography-root .MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 5px;
}

.confirmModalBox {
    text-align: center;
    margin: 15px 0;
}

.confirmModalTitle {
    display: block;
    text-align: center;
}

.confirmModalBox .MuiButtonBase-root {
    font-size: 14px !important;
}

.version-list-item .version-info {
    text-align: left;
    padding-left: 10px;
    width: 100%;
}

.version-list-item .version-info h6 {
    font-size: 15px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.version-list-item .version-info p {
    font-size: 12px;
    margin-top: -2px;
    padding: 2px 0;
}

.version-list-item .version-actions {
    display: flex;
    align-items: center;
}

.version-list-item {
    display: flex;
    padding: 15px 10px;
    align-items: center;
    border-bottom: 1px solid #dedede;
}

.version-list-item .MuiButtonBase-root {
    min-width: unset;
}

.version-list-item .version-info p span {
    font-weight: 500;
}

.versions-actions .current-version {
    color: #fff;
    background: #66CFA4;
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 50px;
    font-weight: 600;
}

.version-list-item.not-found p {
    text-align: center;
    width: 100%;
    display: block;
    font-size: 14px;
}


.logs-list-item .logs-info {
    text-align: left;
    padding-left: 10px;
    width: 100%;
}

.logs-list-item .logs-info h6 {
    font-size: 13px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.logs-list-item .logs-info p {
    font-size: 12px;
    margin-top: -2px;
    padding: 2px 0;
}

.logs-list-item .logs-actions {
    display: flex;
    align-items: center;
}

.logs-list-item {
    display: flex;
    padding: 15px 10px;
    align-items: center;
    border-bottom: 1px solid #dedede;
}

.logs-list-item .MuiButtonBase-root {
    min-width: unset;
}

.logs-list-item .version-info p span {
    font-weight: 500;
}


.users-list-wrapper,
.version-list-wrapper {
    max-height: 50vh;
    overflow-y: auto;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.documents-container,
.documents-container .MuiGrid-root {
    display: inline-block !important;
    height: auto;
}

.help-block {
    margin-top: 5px;
}

.help-block.help-block-error {
    color: #e41111;
}

.theme-form-element {
    padding: 7px;
    min-height: 40px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ccc;
    min-width: 100px;
    font-family: "Poppins";
    color: #333;
    text-decoration: none;
    background: transparent;
}

a.theme-form-element {
    padding: 9.28px;
    cursor: pointer;
}

.theme-form-element.fe-lg {
    height: 55.9px;
    padding: 10px 15px;
    min-width: 200px;
}

a.theme-form-element.fe-lg {
    padding: 17.23px 15px;
}

.dms-s-fiters .theme-form-element {
    margin-right: 10px;
}

.w-100 {
    width: 100%;
}

.theme-form-group {
    margin-bottom: 15px !important;
}

.mt-5px {
    margin-top: 5px !important;
}

.mt-7px {
    margin-top: 7px !important;
}

.mt-10px {
    margin-top: 10px !important;
}

.mt-12px {
    margin-top: 12px !important;
}

.mt-15px {
    margin-top: 15px !important;
}

.mt-25px {
    margin-top: 25px !important;
}

.mt-35px {
    margin-top: 35px !important;
}

.ms-5px {
    margin-top: 5px !important;
}

.ms-7px {
    margin-left: 7px !important;
}

.ms-10px {
    margin-left: 10px !important;
}

.ms-12px {
    margin-left: 12px !important;
}

.ms-15px {
    margin-left: 15px !important;
}

.me-5px {
    margin-right: 5px !important;
}

.me-7px {
    margin-right: 7px !important;
}

.me-10px {
    margin-right: 10px !important;
}

.me-12px {
    margin-right: 12px !important;
}

.me-15px {
    margin-right: 15px !important;
}

.mb-5px {
    margin-bottom: 5px !important;
}

.mb-7px {
    margin-bottom: 7px !important;
}

.mb-10px {
    margin-bottom: 10px !important;
}

.mb-12px {
    margin-bottom: 12px !important;
}

.mb-15px {
    margin-bottom: 15px !important;
}

.mb-25px {
    margin-bottom: 25px !important;
}

.mb-35px {
    margin-bottom: 35px !important;
}

.mb-50px {
    margin-bottom: 50px !important;
}

.text-center {
    text-align: center;
}


.document-tile {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.document-tile .document-image {
    margin-right: 10px;
}

.document-tile .document-image img {
    height: 35px;
    width: auto;
}

.document-tile .document-details {
    text-align: left;
    width: 100%;
}

.document-tile .document-details h6 {
    font-size: 16px;
    overflow: auto;
    text-overflow: ellipsis;
    width: 97%;
    height: 30px;
    margin: 0;
    white-space: nowrap;
}

.document-tile .document-details p {
    margin: 0;
}

.table-default {
    border-collapse: collapse;
    width: 100%;
    font-family: Poppins;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.table-default td,
.table-default th {
    padding: 12px 15px;
}

.table-default tbody tr {
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.table-default th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background: transparent;
    font-weight: normal;
}

.table-responsive {
    width: 100%;
    overflow-x: auto;
}

.table-default tbody {
    background: #fff;
}

.table-row-title {
    display: flex;
    align-items: center;
    font-size: 1.10rem;
}

.table-row-title img {
    height: 20px;
    width: auto;
    margin-right: 15px;
}

.table-green-head th {
    background: #66CFA4;
    color: #fff;
}

.table-default tbody tr.selected {
    border: 1px solid #66CFA4;
    border-top-width: 1.5px;
}

.MuiAlert-message>.MuiLinearProgress-indeterminate>.MuiLinearProgress-barColorPrimary {
    background-color: #3a89db;
}

.select-sm-width {
    width: 165px !important;
    height: 35px !important;
}

.MuiButton-contained.Mui-disabled {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12) !important;
}


/* magazine styles here */
.magazine {
    margin: 0 auto;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.magazine .page {
    height: 100%;
}

.magazine .page img {
    max-width: 100%;
    height: 100%;
}



.doc-viewer-modal .MuiPaper-root {
    padding: 0;
    box-shadow: none !important;
    height: 100%;
    width: 100%;
}

.MuiPaper-root .document-preview {
    height: 100%;
    min-height: 60vh;
}

.MuiPaper-root .document-preview iframe {
    height: 100%;
    min-height: 60vh;
}

.document-detail-card {
    min-height: 60vh;
}

.document-detail-card-title {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.document-detail-card-title img {
    height: 35px;
    width: auto;
    margin-bottom: 10px;
}

.document-detail-card-title span {
    font-size: 18px;
}

.dms-breadcrumbs {
    padding-bottom: 0 !important;
}

.dms-breadcrumbs ul {
    list-style: none;
    padding: 0;
    width: auto;
    margin: 0;
    background: #eee;
    display: inline-flex;
    padding: 10px 15px;
}

.dms-breadcrumbs ul li {
    display: inline;
    font-size: 14px;
}

.dms-breadcrumbs ul li+li:before {
    padding: 8px;
    color: black;
    content: "/\00a0";
}

.dms-breadcrumbs ul li a {
    color: #333;
    text-decoration: none;
    font-family: "Poppins";
}

.dms-breadcrumbs ul li a:hover {
    cursor: pointer;
    text-decoration: none;
    color: #66CFA4;
}

.dms-breadcrumbs ul li a.active {
    font-weight: 600;
}

.theme-btn {
    color: rgba(0, 0, 0, 0.87);
    border: none;
    cursor: default;
    height: 32px;
    display: inline-flex;
    outline: 0;
    padding: 0 15px;
    font-size: 0.8125rem;
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: Poppins;
    white-space: nowrap;
    border-radius: 16px;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: #e0e0e0;
    cursor: pointer;
}

.theme-btn:hover,
.theme-btn:focus {
    background: #d0d0d0;
}

.theme-btn.primary {
    background-color: #66CFA4;
    color: #fff;
}

.theme-btn.primary:hover,
.theme-btn.primary:focus {
    background: #36c79b;
    color: #fff;
}

.theme-btn.danger {
    background-color: #dc3545;
    color: #fff;
}

.theme-btn.danger:hover,
.theme-btn.danger:focus {
    background: #c82333;
}

.theme-badge {
    color: rgba(0, 0, 0, 0.87);
    border: none;
    cursor: default;
    height: 32px;
    display: inline-flex;
    outline: 0;
    padding: 0 15px;
    font-size: 0.8125rem;
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: Poppins;
    white-space: nowrap;
    border-radius: 16px;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: #e0e0e0;
}

.dashboard-title-header {
    font-family: Poppins;
    font-size: 25px;
    margin: 0;
    font-weight: 600;
    color: #66CFA4;
}

.theme-badge.primary {
    background-color: #66CFA4;
}

.theme-badge.danger {
    background-color: #dc3545;
    color: #fff;
}

.btn-lg {
    font-size: 16px;
    padding: 8px 21px;
    height: auto;
    font-weight: 500;
    color: #333;
    border-radius: 50px;
}

.btn-xl {
    font-size: 15px !important;
    padding: 8px 25px !important;
    height: auto;
    font-weight: 500;
    color: #333;
}

.btn-round {
    border-radius: 50px !important;
}

.d-none {
    display: none !important;
}

.table-row-title-main:hover {
    cursor: pointer;
}

.dms-breadcrumbs ul li a.default {
    font-style: italic;
}

.dms-table-row.default,
.document-title-wrapper.default {
    font-style: italic;
    opacity: 0.7;
}

.dms-table-row:hover,
.document-title-wrapper:hover {
    opacity: 1;
}

.dms-files-area {
    min-height: 60vh;
}

.truDrop {
    position: relative;
}

.truDrop-content {
    border: 2px dashed #66CFA4;
    background-color: #ebf9f5;
    border-radius: 10px;
    position: absolute;
    z-index: 9;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    display: none;
    align-items: flex-start;
    justify-content: center;
}

.truDrop-content-box {
    padding: 20px 25px;
    border: 2px dashed #66CFA4;
    background: transparent;
    border-radius: 10px;
    color: #333;
    margin-top: 30vh;
    text-align: center;
}

.truDrop-content-box h6 {
    font-size: 14px;
    margin: 0;
}

.truDrop-content-box h4 {
    font-size: 16px;
    margin: 10px 0 5px;
}

.truDrop-content-box h4 .folderName {
    background: #66CFA4;
    padding: 5px 10px;
    color: #fff;
    font-size: 15px;
    border-radius: 10px;
    font-weight: 600;
}

.truDrop-active .truDrop-content {
    display: flex;
}

.subSubMenu .menu-box {
    padding-left: 30px;
}

.subSubSubMenu .menu-box {
    padding-left: 45px;
}

.menu-link.active,
.menu-link.active:hover {
    background: #66CFA4;
}

.level-menu.opened {
    background: #3b3b3b;
}


/* .level-menu.subMenu.opened {
    background: #2e2d2d;
}

.level-menu.subSubMenu.opened {
    background: #212121;
} */

.mainMenu .level-menu span {
    font-size: 15px;
}

.d-block {
    display: block !important;
}

.py-50px {
    padding-top: 50px;
    padding-bottom: 50px;
}

.mt-0px {
    margin-top: 0px !important;
}

.file-choose-icons button {
    height: 55px;
    width: 55px;
}

.file-choose-icons .dropbox svg {
    height: 43px !important;
    width: 43px !important;
    padding-top: 20px !important;
    padding-left: 8px !important;
}

.file-choose-icons .google svg {
    padding-top: 10px !important;
    padding-left: 8px !important;
}

.sign-document-signs img {
    height: 100px;
    width: auto;
}

.capital-case {
    text-transform: capitalize;
}

body {
    overflow-x: hidden;
}

.theme-checkbox-small {
    width: 100%;
    display: flex !important;
    justify-content: flex-start;
    margin: 0 !important;
}

.theme-checkbox-small .MuiIconButton-root {
    padding: 0;
}

.theme-checkbox-small .MuiFormControlLabel-label .MuiTypography-body1 {
    font-size: 14px;
    text-align: left;
    margin-left: 5px;
}

.fieldWithFile label:not(.MuiInputLabel-shrink) {
    display: none;
}

.form-sub-header {
    font-family: Poppins;
    font-size: 18px;
    margin: 0;
    font-weight: 500;
}

.theme-form-elem {
    width: 100%;
}

.theme-form-elem .MuiInputBase-root {
    border-radius: 50px;
}

.theme-form-elem .MuiOutlinedInput-notchedOutline {
    border-color: #66CFA4;
}

.theme-form-elem-signs img {
    height: 50px;
    width: auto;
}

.sign-options img {
    height: 100px;
    width: auto;
}

.theme-form-elem-signs.selected div.MuiOutlinedInput-input {
    padding-top: 0;
    padding-bottom: 0;
}

.maker-form {
    padding: 15px 0;
}

.field-help-text,
.field-help-text a {
    margin: 5px 0;
    font-family: 'Poppins';
    font-size: 12px;
    color: #333;
    text-decoration: none;
}

.field-help-text a {
    color: #66CFA4 !important;
}

.text-right {
    text-align: right;
}

.field-help-text a {
    cursor: pointer;
    color: #66CFA4 !important;
}

.loading_report_div {
    display: flex;
    justify-content: center;
    width: 100% !important;
    height: 100% !important;
}

.more-options-menu .MuiMenu-paper {
    border: 1px solid #d3d4d5;
}

.menu-box .MuiListItemText-root span {
    word-break: break-word;
}

.chat-wrapper {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: 20px;
}

.chat-left-panel {
    background: #FAFAFA;
}

.chat-left-head {
    padding: 20px 15px 5px;
}

.chat-details {
    background: #FAFAFA;
    width: 100%;
    max-width: 360px;
    flex-grow: 1 !important;
}

.chat-message-area {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chat-message-area .chat-messages-box {
    width: 100%;
    padding: 15px 20px;
    overflow: scroll;
}

.chat-message-area .chat-message-form {
    max-height: 85px;
    width: 100%;
    flex-grow: 0 !important;
    padding: 0 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.chat-message-box {
    height: 100%;
    width: 100%;
    flex-grow: 1 !important;
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: relative;
}

.chat-left-panel-search {
    margin: 15px 0 !important;
}

.chat-left-panel-search .MuiOutlinedInput-root {
    border-radius: 50px;
}

.chat-panel {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.chat-panel-header-main {
    margin-top: 0;
}

.chat-panel-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 15px;
    text-align: left;
    display: flex;
    align-items: center;
    width: 100%;
}

.chat-profile-box {
    display: flex;
    align-items: center;
}

.chat-profile-box .img {
    height: 42px;
    min-width: 42px;
    width: 42px;
    background: #eee;
    background-size: 60% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    border-radius: 50px;
    position: relative;
    border: 1px solid #ccc;
}

.chat-profile-box.chat .img {
    background-size: 45% !important;
}

.chat-profile-box .info {
    margin-left: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.chat-profile-box .info h6 {
    font-size: 15px;
    margin: 0 !important;
    line-height: normal;
    font-weight: 600;
    line-height: 15px;
    white-space: normal;
    word-break: break-word;
    line-height: 20px;
}

.chat-profile-box .message-count {
    background: #69CFA5;
    font-family: 'Poppins';
    color: #fff;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    margin-left: 5px !important;
    position: absolute;
    bottom: -3px;
    right: -3px;
}

.chat-profile-box .info span {
    font-family: 'Poppins';
    font-size: 12px;
    margin: 0 !important;
    line-height: normal;
}

.chat-profile-box .actions {
    position: relative;
    margin-left: auto;
    flex-grow: 1 !important;
    text-align: right;
    margin-right: 15px;
    padding-right: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
}

.chat-profile-box .date {
    font-family: 'Poppins';
    font-size: 12px;
}

.msg {
    display: flex;
    align-items: flex-end;
    font-family: 'Poppins';
    margin-bottom: 10px;
}

.msg:last-of-type {
    margin: 0;
}

.msg-img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    background: #eee;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
}

.msg-bubble {
    max-width: 450px;
    padding: 15px;
    border-radius: 15px;
    background: #F5F5F5;
}

.msg-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}

.msg-info-name {
    margin-right: 10px;
    font-size: 12px;
    color: #777;
}

.msg-info-time {
    font-size: 12px;
    color: #777;
}

.left-msg .msg-bubble {
    border-bottom-left-radius: 0;
}

.right-msg {
    flex-direction: row-reverse;
}

.right-msg .msg-bubble {
    background: #69CFA5;
    color: #fff;
    border-bottom-right-radius: 0;
}

.right-msg .msg-info {
    justify-content: flex-end;
}

.right-msg .msg-info-name {
    margin-right: 0;
    margin-left: 10px;
}

.right-msg .msg-img {
    margin: 0 0 0 10px;
}

.msg-text {
    font-size: 14px;
}

.chat-peoples .chat-profile-box {
    width: 100%;
    padding: 10px 15px;
    justify-content: flex-start;
}

.chat-peoples .chat-profile-box.active {
    background: #efefef;
}

.chat-peoples .chat-profile-box:hover {
    background: #f1f1f1;
}

.chat-peoples {
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    overflow: hidden;
    overflow-y: scroll;
}

.chat-profile-box .actions .accept-chat {
    font-family: 'Poppins';
    font-size: 12px;
    background: #69CFA5;
    color: #fff;
    padding: 2px 8px;
    border-radius: 50px;
    cursor: pointer;
}

.chat-profile-box .actions .accept-chat:hover,
.chat-profile-box .actions .accept-chat:focus {
    background: #5fc49a;
}

.chat-peoples {
    max-height: 65vh;
}

.chat-peoples-empty {
    flex-grow: 1 !important;
}

.chat-peoples-empty h6 {
    text-align: center;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
}

.chat-message-area .chat-messages-box {
    max-height: calc(85vh - 160px);
}

.chat-peoples .chat-profile-box {
    padding: 15px;
}

.chat-profile-box {
    cursor: pointer;
}

.chat-head-option {
    display: flex;
    align-items: center;
}

.chat-head-option .chat-head-actions {
    margin-right: 0;
    margin-left: auto;
    padding-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-head-option .chat-head-actions a {
    background: #69D0A5;
    color: #fff;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}

.chat-head-option .chat-head-actions a svg {
    width: 20px;
}

.chat-head-option .chat-head-actions a:hover,
.chat-head-option .chat-head-actions a:focus {
    background: #5fc49a;
    cursor: pointer;
}

.chat-left-panel-search .MuiOutlinedInput-input {
    padding: 15px;
}

.chat-left-panel-search .MuiFormLabel-root {
    transform: translate(14px, 18px) scale(1);
}

.chat-message-area .chat-message-form {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-message-area .chat-message-form a:first-child {
    margin-right: 10px;
    padding-top: 5px;
}

.chat-message-area .chat-message-form a:last-child {
    margin-left: 10px;
}

.chat-message-area .chat-message-form a {
    color: #69D0A5;
    cursor: pointer;
}

.chat-details {
    display: none;
}

.chat-message-box.show-details .chat-details {
    display: block;
}

.chat-details-profile .chat-profile-box {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 25px 0;
}

.chat-details-profile .chat-profile-box .img {
    height: 65px;
    width: 65px;
}

.chat-details-profile .chat-profile-box .info {
    margin: 10px 0 0;
    width: 100%;
}

.chat-details-profile .chat-profile-box .info * {
    text-align: center;
    width: 100%;
}

.chat-panel-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1 !important;
}

.chat-panel-empty h6 {
    font-family: 'Poppins';
    font-size: 24px;
    margin: 0;
}

.chat-panel-empty p {
    font-family: 'Poppins';
    margin: 5px 0 0;
    font-size: 14px;
}

.chat-panel-empty svg {
    opacity: 0.8;
    height: 40px;
    width: 40px;
}

.chat-messages-loading {
    font-family: 'Poppins';
    font-size: 14px;
    display: block;
    text-align: center;
    font-weight: 400;
}

.load-more-chats {
    font-family: 'Poppins';
    font-size: 12px;
    display: inline-block;
    text-align: center;
    background: #eee;
    padding: 4px 25px;
    border-radius: 50px;
    cursor: pointer;
}

.load-more-box-t {
    display: block;
    text-align: center;
    padding-bottom: 15px;
}

.chat-profile-box.chat-details-profile-box {
    flex-direction: column;
    padding: 25px 15px;
    text-align: center;
    position: relative;
}

.chat-profile-box.chat-details-profile-box .info {
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-left: 0;
}

.chat-profile-box.chat-details-profile-box .img {
    height: 75px;
    width: 75px;
}

.members-list-item {
    display: flex;
    padding: 15px 10px;
    align-items: center;
    border-bottom: 1px solid #dedede;
}

.members-list-item .MuiButtonBase-root {
    min-width: unset;
}

.members-list-item.add-new {
    background: #eee;
    text-align: center;
    padding: 0;
}

.members-list-item.add-new p.MuiTypography-root {
    font-size: 14px;
    width: 100%;
    text-align: center;
    padding: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.members-list-item.not-found p {
    text-align: center;
    width: 100%;
    display: block;
    font-size: 14px;
}

.members-list-item.add-new p.MuiTypography-root .MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 5px;
}

.members-list-item .MuiAvatar-root {
    margin: 0;
    width: 50px;
    height: 50px;
    font-size: 20px;
}

.members-list-item .user-info {
    text-align: left;
    padding-left: 10px;
    width: 100%;
}

.members-list-item .user-info h6 {
    font-size: 15px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.members-list-item .user-info p {
    font-size: 13px;
    margin-top: -5px;
}

.members-list-item .user-actions {
    display: flex;
    align-items: center;
}

.members-list-item.not-manage-users h6 {
    max-width: 100% !important;
}

.chat-details-tabs .MuiTabPanel-root {
    padding: 0 !important;
}

.chat-profile-box.accept-pending .img {
    background-color: rgb(250, 163, 163) !important;
}

.chat-profile-box.chat-details-profile-box a.close-chat-details {
    position: absolute;
    right: 10px;
    top: 10px;
    opacity: 0.5;
}

.chat-profile-box.chat-details-profile-box a.close-chat-details:hover {
    opacity: 1;
}

.chat-message-area .chat-message-form p {
    font-family: 'Poppins';
}

.attachments-list-item {
    display: flex;
    padding: 15px 10px;
    align-items: center;
    border-bottom: 1px solid #dedede;
}

.attachments-list-item .MuiButtonBase-root {
    min-width: unset;
}

.attachments-list-item.add-new {
    background: #eee;
    text-align: center;
    padding: 0;
}

.attachments-list-item.add-new p.MuiTypography-root {
    font-size: 14px;
    width: 100%;
    text-align: center;
    padding: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.attachments-list-item.not-found p {
    text-align: center;
    width: 100%;
    display: block;
    font-size: 14px;
}

.attachments-list-item.add-new p.MuiTypography-root .MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 5px;
}

.attachments-list-item .MuiAvatar-root {
    margin: 0;
    width: 50px;
    height: 50px;
    font-size: 20px;
}

.attachments-list-item .user-info {
    text-align: left;
    padding-left: 10px;
    width: 100%;
}

.attachments-list-item .user-info h6 {
    font-size: 15px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.attachments-list-item .user-info p {
    font-size: 13px;
    margin-top: -5px;
}

.attachments-list-item .user-actions {
    display: flex;
    align-items: center;
}

.attachments-list-item.not-manage-users h6 {
    max-width: 100% !important;
}

.document-detail-card {
    border-left: 1px solid #ccc !important;
}

.MuiTab-textColorInherit.Mui-selected {
    background: #3ac189;
}

.MuiTabs-indicator {
    display: none !important;
}

.MuiTabs-centered {
    justify-content: stretch !important;
}

.MuiTabs-centered .MuiTab-root {
    flex-grow: 1 !important;
    max-width: unset !important;
}

.members-list-wrapper,
.attachments-list-wrapper {
    max-height: calc(100% - 75px);
    overflow-y: auto;
}

.chat-left-panel,
.chat-panel {
    min-height: 85vh;
}

.chat-message-box.show-details .chat-details {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    max-height: calc(85vh - 75px);
}

.chat-message-box.show-details .chat-details .chat-details-tabs {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100%;
}

.chat-message-box.show-details .chat-details .chat-details-tabs .MuiTabPanel-root {
    height: auto;
    flex-grow: 1;
    overflow-y: scroll;
    min-height: calc(100%);
    max-height: calc(100% - 45px);
}

.chat-panel-header {
    width: 100%;
    display: block;
}

.msg-text a.chat-attachment-box {
    border: 1px solid #fff;
    display: block;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    font-size: 16px;
}

.msg-text a.chat-attachment-box:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.1);
}

.msg-text a.chat-attachment-box h6 {
    margin: 0;
}

.attachments-list-item:hover {
    cursor: pointer;
}

.stats-box-link:hover {
    cursor: pointer;
}

.document-detail-card {
    position: sticky;
    top: 65px;
}

.document-detail-card.without-options {
    min-height: 215px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dms-main-box.fileSelector {
    margin-left: 0;
    width: 100%;
}

.dms-files-area {
    width: 100%;
}

.MuiGrid-root.makeStyles-filterBar2-150 {
    margin: 0;
}

.file-choose-icons .dms img {
    height: 35px;
    margin-top: -3px;
}

.dms-main-box.fileSelector .dms-breadcrumbs {
    padding: 0 15px !important;
}

.dms-main-box.fileSelector .dms-files-area {
    margin-top: -15px;
}

.content-primary-link {
    color: #3ac189;
    text-decoration: none;
}

.sign-document-signs-field {
    width: 100%;
}

.switch-box-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.switch-box h6 {
    margin: 0;
    font-weight: normal;
    font-size: 15px;
}

.switch-box {
    border: 1px solid #dedede;
    width: 100%;
    display: block;
    margin: 7.5px;
    border-radius: 4px;
}

.switch-box .box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    cursor: pointer;
}

.switch-box-container .switch-box:first-child {
    margin-left: 0;
}

.switch-box-container .switch-box:last-child {
    margin-right: 0;
}

.switch-box .box .circle {
    display: block;
    width: 22px;
    height: 22px;
    border: 1px solid #dedede;
    border-radius: 50px;
    margin-right: 10px;
    position: relative;
}

.switch-box .box .circle::after {
    content: "";
    height: 12px;
    width: 12px;
    position: absolute;
    background: #66D0A4;
    border-radius: 50px;
    left: 5px;
    top: 5px;
    display: none;
}

.switch-box.active {
    border-color: #66D0A4;
}

.switch-box.active .box .circle {
    border-color: #66D0A4;
}

.switch-box.active .box .circle::after {
    display: block;
}

.cms-content-area {
    width: calc(100% - 150px);
    margin: 0 auto;
    display: block;
}

.cms-content-area * {
    color: "#262727";
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins";
    line-height: 27px;
}

.cms-content-area h4 {
    font-weight: 600;
    font-size: 18px;
}

.cms-page-title {
    margin-top: 35px !important;
}

.cms-upper-head-link {
    margin-top: 25px !important;
}

.not-registered {
    opacity: 0.5;
}

.event-modal-title {
    font-family: "Poppins";
}

.event-modal-description {
    font-family: "Poppins";
    padding: 10px 0;
    display: block;
}

.event-modal-short-desc {
    font-family: "Poppins";
    padding: 10px 0;
    display: block;
    color: #66CFA4;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.event-modal-content.event .event-modal-short-desc {
    color: #74BCFE;
}

.event-modal-short-desc .event-type {
    text-transform: capitalize;
}

.event-modal-short-desc span {
    position: relative;
    padding-right: 20px;
}

.event-modal-short-desc span::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background: #dedede;
    right: 10px;
    top: 0;
}

.event-modal-short-desc span:last-child:after {
    display: none;
}

.full-calendar-wrapper {
    background-color: #fff;
    padding: 25px;
}

.full-calendar-wrapper * {
    font-family: "Poppins";
}

.full-calendar-wrapper .fc-toolbar-title {
    font-family: Poppins;
    font-size: 25px;
    margin: 0;
    font-weight: 600;
    color: #66CFA4;
}

.full-calendar-wrapper .fc-toolbar-chunk button {
    text-transform: capitalize !important;
}

.full-calendar-wrapper .fc .fc-daygrid-day.fc-day-today {
    background-color: #f0fbf6;
}

.full-calendar-wrapper .fc-button-primary {
    background: #66CFA4;
    border-color: #66CFA4;
}

.full-calendar-wrapper .fc-button-primary:disabled {
    background: #66CFA4;
    border-color: #66CFA4;
}

.full-calendar-wrapper .fc-button-primary:hover,
.full-calendar-wrapper .fc-button-primary:focus {
    background: rgb(71, 144, 114);
    border-color: rgb(71, 144, 114);
}

.full-calendar-wrapper .fc .fc-col-header-cell-cushion {
    padding: 5px;
    font-weight: normal;
    background: #eee;
    display: block;
    font-size: 14px;
}

.full-calendar-wrapper .fc .fc-daygrid-day-number {
    padding: 4px 10px;
    font-size: 16px;
    font-family: system-ui;
}

.full-calendar-wrapper .fc-event {
    text-align: center;
    background: #74BCFE;
    border-color: #74BCFE;
    cursor: pointer;
}

.full-calendar-wrapper .fc-event.event-type-meeting {
    background: #66CFA4;
    border-color: #66CFA4;
}

.theme-card {
    background: #fff;
    font-family: "Poppins";
    /* border: 1px solid #dedede; */
    border-radius: 0px;
}

.theme-card .theme-card-head {
    padding: 25px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
}

.theme-card .theme-card-head .theme-card-actions {
    margin-right: 0;
    margin-left: auto;
}

.theme-card .theme-card-title h1 {
    font-size: 21px;
    margin: 0;
    font-weight: 600;
    color: #66CFA4;
}

.theme-card .theme-card-title h6 {
    font-size: 14px;
    color: #999;
}

.theme-card .theme-card-body {
    padding: 25px;
    padding-top: 0;
}

.event-box {
    border: 1px solid #dedede;
    padding: 15px;
    border-radius: 25px;
    margin-bottom: 15px;
}

.event-box-main {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.event-box-description {
    padding: 15px 0;
    display: none;
}

.event-box.expand .event-box-description {
    display: block;
}

.event-box-description span {
    display: block;
    width: 100%;
    font-size: 14px;
}

.event-box-title {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding-right: 25px;
}

.event-box-title .theme-checkbox {
    width: auto !important;
    margin-right: 15px !important;
}

.event-box-title h4 {
    font-size: 16px;
    font-weight: 500;
}

.event-box-middle {
    margin: 0 auto;
}

.event-box-middle h6 {
    font-size: 16px;
    color: #555;
    font-weight: normal;
}

.event-box-short-desc {
    margin-left: auto;
    margin-right: 0;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 25px;
}

.event-box-short-desc h6 {
    font-size: 14px;
}

.event-list-box {
    margin-top: 15px;
}

.help-block {
    font-family: "Poppins";
    font-size: 12px;
}

.event-box-middle h6 {
    text-transform: capitalize;
}

.event-box-no-results {
    width: 100%;
    text-align: center;
}

.event-box-no-results h6 {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 14px;
    color: #777;
}

.event-box-short-desc {
    flex-direction: column;
}

.event-box-short-desc h6,
.event-box-short-desc .event-box-short-actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.event-box-short-desc .event-box-short-actions a {
    display: block;
    font-size: 14px;
    color: #66CFA4;
    margin-left: 10px;
}

.event-box.completed .event-box-title h4 {
    opacity: 0.5;
    text-decoration: line-through;
}

.event-box-short-desc .event-box-short-actions a:first-child {
    margin-left: 0;
}

.event-box-short-desc .event-box-short-actions a:last-child {
    color: rgb(237, 66, 66);
}

.full-calendar-wrapper .fc-event {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 5px;
}

.full-calendar-wrapper .fc-event p {
    margin: 0;
    line-height: 14px;
    white-space: pre-wrap;
    text-align: left;
    font-size: 12.5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.full-calendar-wrapper .fc-event {
    overflow: hidden;
}

.full-calendar-wrapper .fc-event b {
    font-size: 10px;
}

body[data-page^="/sustainability"] .app-bar-main,
body[data-page^="/sustainability"] .app-drawer-main {
    display: none;
}

.sustainability-layout .toggle_button {
    display: none;
}

.sustainability-layout .toggle_button button {
    background-color: white;
    border: 1px solid rgba(172, 172, 172, 0.599);
    border-radius: 6px;
    margin: 6px 17px;
    cursor: pointer;
}

.sustainability-layout .toggle_button img {
    height: fit-content;
    margin: 8px 17px;
}

.sustainability-layout img {
    max-width: 100%;
}

/* div.google-visualization-tooltip {

} */

svg>g:last-child>g:last-child {
    pointer-events: none
}

div.google-visualization-tooltip {
    pointer-events: none
}

div.google-visualization-tooltip .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

img.goal-images {
    max-width: 50% !important;
    height: 70px;
    width: 70px !important;
}

.sustainability-layout * {
    font-family: 'pangram';
    font-weight: 500;
}

.sustainability-layout .btnn button {
    padding: 16px 79px !important;
}

.sustainability-layout .navbar_main {
    width: 100%;
    z-index: 99;
    position: fixed;
    left: 0;
    top: 0;
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(32, 32, 32, 0.113), 0 2px 30px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 13px 24px;
    align-items: center;
    transition: all 0.4s ease-in-out;
    column-gap: 20px;
}

.sustainability-layout .navbar_links a,
.sustainability-layout .history_link a {
    padding: 10px 0 4px 0;
    margin: 0 17px;
    text-decoration: none;
    font-family: 'Pangram';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--sustainability-black);
    opacity: 0.4;
    transition: all 0.3s ease-in;
}

/* .sustainability-layout .navbar_links span {
    padding: 7px 0;
} */

.sustainability-layout .go_back_button button {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    font-family: 'Pangram';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}

.sustainability-layout .divider {
    border-left: 1px solid black;
}

.sustainability-layout .history_link {
    display: flex;
}

.sustainability-layout .navbar_links a.active {
    opacity: 1;
    border-bottom: 1px solid var(--sustainability-primary);
}

.sustainability-layout .navbar_links a:hover {
    opacity: 1;
    cursor: pointer;
}

.sustainability-layout .history_link a:focus {
    opacity: 1;
    border-bottom: 1px solid var(--sustainability-primary);
}

.sustainability-layout .add {
    color: #6078f1 !important;
    opacity: 1 !important;
}

.sustainability-layout .outlet_here {
    margin-top: 71px;
    padding: 11px 0;
}

.sustainability-layout .upload button {
    background: var(--sustainability-primary_btn);
    border-radius: 6px;
    border: none;
    color: var(--sustainability-white);
    padding: 14px 21px !important;
    display: flex;
    align-items: center;
    margin: auto;
    cursor: pointer;
}

.sustainability-layout .upload_wrapper h4 {
    margin: 34px 0 24px 0 !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.sustainability-layout .shadow-none {
    box-shadow: none !important;
    border: 1px solid #e0e0e0;
}

.sustainability-layout [role=tooltip].popup-content {
    width: 80px;
    padding: 10px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .16);
    border-radius: 15px;
}

.sustainability-layout .card_flex {
    display: flex;
}

.sustainability-layout .dots_button {
    cursor: pointer;
}

.sustainability-layout .card_flex img {
    margin: 0 14px 0 0;
}

.sustainability-layout .text-gradient {
    /* -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, #66D0A4 0%, #66D0A4 100%);
    -webkit-background-clip: text; */
    color: var(--sustainability-primary) !important;
}

.sustainability-layout .about_wrapper h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    margin: 33px 0 10px 0 !important;
    line-height: 41px;
    color: var(--sustainability-primary);
}

.sustainability-layout .upload_wrapper {
    position: relative;
    height: 391px;
    background: var(--sustainability-xtralight);
    border: 2px dashed var(--sustainability-primary);
    border-radius: 10px;
}

.sustainability-layout .upload h3 {
    font-family: 'Pangram';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    opacity: 0.8;
}

.sustainability-layout .h4 {
    margin: 34px 0 24px 0 !important;
}

.sustainability-layout .upload {
    text-align: center;
}

.sustainability-layout .main_upload {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sustainability-layout .file_cards {
    font-family: 'Pangram';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000;
}

.sustainability-layout .main_upload input {
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.sustainability-layout .steps {
    display: flex;
    padding-bottom: 8px;
}

.sustainability-layout .undsg_main h1 {
    font-weight: 700;
    font-size: 34px;
    margin: 33px 0 3px 0 !important;
    line-height: 41px;
    color: var(--sustainability-primary);
}

.sustainability-layout .steps p {
    padding: 5px;
    color: #8d8d8d a7;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

.sustainability-layout .steps span {
    border-right: 1px solid black;
}

.sustainability-layout .environment {
    padding: 28px 39px;
    text-align: center;
}

.sustainability-layout .environment h1 {
    color: black;
    font-weight: 500;
    margin: 0 !important;
    font-size: 24px;
    line-height: 36px;
}

.sustainability-layout .topg {
    opacity: 0.5;
    margin-bottom: 20px;
}

.sustainability-layout .topg li {
    color: rgba(0, 0, 0, 0.437);
}

.sustainability-layout .bottomg li {
    color: #3f91ce;
}

.sustainability-layout .bottomg_gov li {
    color: #f04594;
}

.sustainability-layout .bottomg_soc li {
    color: #6d55ab;
}

.sustainability-layout .environment li {
    list-style: none;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 3px 0;
}

.sustainability-layout .environment_image {
    padding: 32px 0;
    display: flex;
    margin: auto;
    color: #307de0;
    width: auto;
    height: 120px;
}

.sustainability-layout .unsdg2_relevance p {
    color: rgba(154, 154, 154, 0.651);
}

.sustainability-layout .unsdg2_relevance select {
    padding: 5px 7px;
    border-radius: 4px;
}

.sustainability-layout .check_box {
    position: relative;
}

.sustainability-layout .active_icon {
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.sustainability-layout .icon_wrapper {
    display: none;
}

.sustainability-layout .icon_wrapper:focus {
    display: block;
}

.sustainability-layout .hover_button {
    position: absolute;
    top: 0;
    width: 100%;
    height: 91%;
    background: #000 82;
    opacity: 0;
    transition: 0.3s ease-in;
    background: rgba(0, 0, 0, 0.75);
    transform: rotateY(180deg);
}

.sustainability-layout .info_icon {
    display: flex;
    justify-content: end;
    padding: 6px;
    font-size: 14px;
}

.sustainability-layout .button_on_hover button {
    padding: 5px;
    border: 1.5px solid #fff;
    background: rgba(255, 255, 255, 0.43);
    color: #333;
    cursor: pointer;
    width: 85%;
    border-radius: 3px;
}

.sustainability-layout .active_icon svg {
    color: #fff;
}

.sustainability-layout .image_box {
    overflow: hidden;
    position: relative;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.sustainability-layout .outer_image {
    perspective: 1000px;
}

.sustainability-layout .image_box img {
    width: 100%;
    height: 100%;
}

.sustainability-layout .pop_btn button {
    cursor: pointer;
    background-color: transparent;
    border: none;
}

.sustainability-layout .button_on_hover {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
}

.sustainability-layout .front_image .hover_button {
    -webkit-backface-visibility: hidden;
    /* Safari */
    backface-visibility: hidden;
}

.sustainability-layout .outer_image:not(.selected) .image_box:hover {
    transform: rotateY(180deg);
    opacity: 1;
}

.sustainability-layout .outer_image:not(.selected) .image_box:hover .hover_button {
    opacity: 1;
}

.sustainability-layout .image_box.active .icon_wrapper {
    display: block;
}

.sustainability-layout .hover_button:hover {
    display: block;
}

.sustainability-layout .objective_main h1 {
    color: #6078f1;
    padding: 10px 0 10px;
}

.sustainability-layout .card_input {
    width: 98%;
    height: 50%;
}

.sustainability-layout .card_button {
    border: none;
    color: #307de0;
    background: var(--sustainability-white);
    width: 100%;
    height: 48%;
}

.sustainability-layout .go_back_button img {
    height: 30px;
    width: auto;
    margin-left: 15px;
}

.sustainability-layout .history_link a {
    padding-top: 6px;
}

.unsdg-goal-icons {
    margin-top: 25px;
}

.unsdg-goal-icons ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 15px;
}

.document-card-box {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 1.5px solid #E0E0E0;
    border-radius: 6px;
    padding: 15px;
}

.document-card-box-title {
    display: flex;
    align-items: center;
}

.document-card-box-title img {
    height: 35px;
    margin-right: 10px;
}

.document-card-box-title h3 {
    margin: 0;
}

.document-card-box-actions {
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-left: auto;
    font-size: 24px;
    color: #999;
}

.goal-icon-trigger {
    cursor: pointer;
}

.goal-icon-trigger:hover {
    cursor: pointer;
}

.sustainability-layout .info_icon {
    position: absolute;
    top: 0;
    right: 0;
}

.sustainability-layout .button_on_hover button {
    margin-top: 5px;
}

.sustainability-layout .button_on_hover button:hover {
    background: rgba(255, 255, 255, 1);
}

.sustainability-layout .steps {
    align-items: center;
}

.sustainability-layout .steps p {
    align-items: center;
    display: flex;
}

.sustainability-layout .steps p.completed svg {
    color: var(--sustainability-primary) !important;
}

.sustainability-layout .steps p.active {
    color: var(--sustainability-primary);
}

.outer_image.selected .icon_wrapper {
    display: block;
}

.sustainability-layout .active_icon {
    background: rgba(0, 0, 0, 0.35);
    height: 94%;
    color: #fff;
    z-index: 9999;
    cursor: pointer;
}

.goal-relevance-info img {
    height: 65px;
    margin-right: 15px;
}

.goal-relevance-info {
    display: flex;
    align-items: center;
    padding: 15px 0;
}

.goal-relevance-item:not(:first-child) {
    border-top: 1px solid #ccc;
}

.unsdg2_relevance p {
    font-size: 14px;
}

.sustainability-layout .unsdg2_relevance select {
    margin-right: 0;
    margin-left: auto;
    display: flex;
    border-color: #ccc;
}

.sustainability-layout .no-results {
    color: #999;
    text-align: center;
    display: block;
}

.undsg_main span[class^="PrivateValueLabel-circle"] {
    display: none;
}

.MuiSlider-thumb .MuiSlider-valueLabel {
    display: none;
}

.undsg_main .MuiSlider-root,
.undsg_main .MuiSlider-track,
.undsg_main .MuiSlider-rail {
    height: 6px;
}

.undsg_main .MuiSlider-thumb {
    width: 17px;
    height: 17px;
}

.unsdg-objective img {
    height: 65px;
    width: auto;
}

.unsdg-objective {
    position: relative;
}

.unsdg-objective input {
    padding: 15px;
    border: 1px solid #dedede !important;
}

.unsdg-objective input:hover,
.unsdg-objective input:focus {
    border: 1px solid #dedede !important;
    box-shadow: none;
    outline: none;
}

.unsdg-objective .MuiPaper-elevation1 {
    box-shadow: none;
    background: transparent;
}

.sustainability-layout .card_input {
    box-sizing: border-box;
}

.unsdg-objective .objective-actions {
    position: absolute;
    right: 25px;
    bottom: 7px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}

.unsdg-objective .objective-actions button {
    background: transparent;
    width: auto;
    cursor: pointer;
}

.unsdg-objective .objective-actions button.remove {
    color: #EE402D;
}

.unsdg-objective .round-progress-bar svg circle {
    stroke: inherit;
}

.unsdg-objective .progress-bar-parent .progress-bar-label {
    font-weight: 600;
    font-size: 10px;
    color: #000;
    text-transform: capitalize;
}

.progress-bar-parent {
    position: relative;
    display: inline-flex;
}

.progress-bar-parent .progress-bar-label {
    position: absolute;
    width: 100%;
    text-align: center;
    height: 92%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-item-titles {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-item-titles span {
    width: 100%;
}

.input-item-main {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 0;
}

.input-item-main .input-item {
    width: 100%;
    padding: 7.5px;
}

.btn-sustainability-box {
    margin-top: 35px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.btn-sustainability-box .left {
    margin-left: 0;
    margin-right: auto;
}

.btn-sustainability-box .right {
    margin-right: 0;
    margin-left: auto;
}

.btn-sustainability {
    background: var(--sustainability-primary_btn);
    color: var(--sustainability-white);
    border: 2px solid var(--sustainability-primary);
    padding: 12px 20px;
    min-width: 160px;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
    font-family: 'pangram';
}

.btn-sustainability:hover {
    background: var(--sustainability-primary_btn-hover);
}

.btn-sustainability.primary-out {
    color: var(--sustainability-primary);
    background: transparent;
}

.btn-sustainability.primary-out:hover {
    background: var(--sustainability-primary_btn);
    color: var(--sustainability-white);
}

.btn-sustainability.secondary {
    color: #333;
    background: #fff;
    border-color: rgb(227, 225, 225);
}

.btn-sustainability.secondary:hover {
    background: #eee;
}

.goals-main .environment {
    height: calc(100% - 60px);
}

.sus-objective-field {
    padding: 15px;
    font-family: 'pangram';
    font-weight: 500;
    width: 100%;
    display: block;
    resize: none;
    border: 1px solid #dedede;
    box-sizing: border-box;
    font-size: 14px;
}

.sus-objective-field:hover,
.sus-objective-field:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid #dedede;
}

.sus-objective-box {
    width: 100%;
    display: block;
}

.sustainability-layout .navbar_links a {
    white-space: nowrap;
}

.sustainability-layout .navbar_links {
    display: flex;
    align-items: center;
}

.sustainability-layout .navbar_links a,
.sustainability-layout .history_link a {
    white-space: nowrap;
    padding: 10px 0 !important;
    margin: 0 7px !important;
}

.toggle .sustainability-layout .navbar_main {
    left: 0;
}

.sustainability-layout .navbar_links_tabs {
    /* max-width: 300px;
    overflow: scroll; */
    display: flex;
}

.sustainability-layout .upl-wrp-style {
    border: none;
    margin-inline: 0;
}

.sustainability-layout .main_upload div[role="button"] {
    width: 100%;
}

.sustainability-layout .document-card-box-actions .delete {
    cursor: pointer;
    color: #999;
    opacity: 0.7;
}

.sustainability-layout .document-card-box-actions .delete:hover {
    opacity: 1;
    color: rgb(229, 59, 59);
}

.sustainability-layout .document-card-box {
    margin-bottom: 15px;
}

.sus-goal-remove {
    position: absolute;
    right: 0;
    top: 0;
    width: 18px !important;
    height: 18px !important;
    background: #fff !important;
    border: 1px solid #ccc !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding-bottom: 4px;
    font-size: 12px;
    color: rgb(233, 75, 75) !important;
}

.sus-goal-remove:hover {
    cursor: pointer;
    border-color: rgb(233, 75, 75) !important;
    background: rgb(233, 75, 75) !important;
    color: #fff !important;
}

.sustainability-layout .navbar_links_tabs span.active a {
    opacity: 1;
    border-bottom: 1px solid var(--sustainability-primary);
}

.sustainability-layout .navbar_links_tabs span {
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.sustainability-layout .navbar_links_tabs span a:first-child {
    margin-right: 0 !important;
    padding-right: 20px !important;
}

.sustainability-layout .navbar_links_tabs span a.remove {
    margin: 0 !important;
    position: absolute;
    right: 5px;
}

.table-actions-btns {
    display: flex;
    align-items: center;
}

.table-actions-btns button {
    display: flex;
    background: transparent;
    border: none;
    padding: 3px;
    align-items: center;
    justify-content: center;
}

.table-actions-btns button svg {
    height: 20px;
}

.table-actions-btns button:hover {
    background: transparent;
    color: #66D0A4;
    cursor: pointer;
}

.normal-txt {
    font-family: poppins;
    display: block;
    font-size: 13px;
}

.card {
    font-family: poppins;
    background: #fff;
    border: 1px solid #dedede;
    border-radius: 10px;
}

.card-header {
    padding: 15px;
    display: flex;
    align-items: center;
}

.card-title {
    margin: 0;
    color: #66CFA4;
    font-weight: 600;
    font-size: 20px;
    margin-right: auto;
    margin-left: 0;
}

.card-actions {
    margin-right: 0;
    margin-left: auto;
}

.card-actions a {
    cursor: pointer;
    border: 1px solid #66CFA4;
    color: #66CFA4;
    padding: 5px 8px;
    border-radius: 15px;
    font-size: 13px;
}

.card-actions a:hover {
    background: #66CFA4;
    color: #fff;
}

.card-body {
    padding: 15px;
}

.info-table {
    width: 100%;
    max-width: 100%;
}

.info-table>tbody>tr>td,
.info-table>tbody>tr>th,
.info-table>tfoot>tr>td,
.info-table>tfoot>tr>th,
.info-table>thead>tr>td,
.info-table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    text-align: left;
    font-size: 15px;
}

.info-table td:not(:first-child):not(:last-child) {
    border-right: 1px solid #dedede;
}

.info-table tr td:first-child,
.info-table tr th:first-child,
.info-table tr td:last-child,
.info-table tr th:last-child {
    padding-left: 0;
}

.pt-0px {
    padding-top: 0 !important;
}

.react-paginate-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0;
}

.react-paginate-wrapper ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
    border-radius: 5px;
    overflow: hidden;
}

.react-paginate-wrapper ul li a,
.react-paginate-wrapper ul li span {
    display: block;
    padding: 7px 15px;
    min-width: 20px;
    background: #fff;
    font-family: poppins;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}

.react-paginate-wrapper ul li.selected a {
    background: #66CFA4;
    color: #fff;
}

.react-paginate-wrapper ul li a:hover {
    cursor: pointer;
    background: #eee;
}

.unsdg-template {
    font-family: 'Pangram';
}

.unsdg-template .main_sdgs_div {
    margin: auto;
}

.unsdg-template .maxw_lesser {
    margin: auto;
}

.unsdg-template p {
    font-size: 14px;
    color: #757575;
    margin: 0 auto;
}

.unsdg-template h1 {
    font-size: 2.5rem;
}

.unsdg-template h2 {
    font-size: 20px;
    color: #333333;
    margin: 0 auto;
    font-weight: 600;
}

.unsdg-template .main_sdgs_div .header_sdgs {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 100px;
}

.unsdg-template .after_line::after {
    content: " ";
    position: absolute;
    top: 20px;
    right: -100px;
    display: flex;
    width: 1px;
    height: 60px;
    background: #333;
}

.unsdg-template .after_line {
    position: relative;
    margin-right: 100px;
}

.unsdg-template .after_line h1 {
    color: #333;
}

.unsdg-template .goals_wrapper {
    margin: 50px 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
}

.unsdg-template .goal_card {
    display: block;
    position: relative;
    height: 100%;
    background: #F5F5F5;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.unsdg-template .main_card_div {
    height: 490px;
    flex-basis: 16.6667%;
    overflow: hidden;
    transition: all 0.3s;
}

.unsdg-template .hover_card {
    width: 100%;
    height: 100%;
    background: #e5233d;
    display: none;
    padding: 0 20px;
    box-sizing: border-box;
}

.unsdg-template .main_card_div:not(.goal_card_last):hover .goal_card {
    display: none;
}

.unsdg-template .main_card_div:not(.goal_card_last):hover .hover_card {
    display: block;
}

.unsdg-template .goal_image {
    position: absolute;
    max-width: 150px;
    bottom: 10;
    left: 10;
    overflow: hidden;
}

.unsdg-template .goal_image img {
    width: 150px;
    height: 150px;
}

.unsdg-template .goals_wrapper .upper_div .goalnumber {
    opacity: 0.28;
    font-size: 32px;
    margin-bottom: 0;
    display: inline-block;
    line-height: 1.11;
    color: white;
}

.unsdg-template .main_sdgs_div .header_sdgs div:not(.after_line) {
    padding-top: 7px;
}

.unsdg-template .goals_wrapper .goaltitle {
    font-size: 18px;
    margin: 10px 0;
    line-height: 1.11;
    color: white;
}

.unsdg-template .goals_wrapper .goaltext {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    color: #ffffff;
}

.unsdg-template .goals_wrapper .lower_div {
    display: block;
}

.unsdg-template .goals_wrapper .dflex_card_lower {
    display: flex;
}

.unsdg-template .goals_wrapper .dflex_card_lower h2 {
    color: white;
}

.unsdg-template .goals_wrapper .dflex_card_lower p {
    color: white;
    font-size: 12px;
}

.unsdg-template .goals_wrapper .dflex_card_lower .number_div {
    width: 50%;
    padding: 10px 0;
}

.unsdg-template .goals_wrapper .lower_div button {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    background: #e5233d;
    border-radius: 1px;
    border: 1px solid #ffffff;
    color: #ffffff;
    justify-content: center;
    font-size: 14px;
    margin: 30px 0;
}

.unsdg-template .video_section_sdgs {
    display: flex;
}

.unsdg-template .video_div_sdgs {
    width: 65%;
}

.unsdg-template .accordion_section_sdgs {
    width: 35%;
}

.unsdg-template .bg_black_accordion_sdgs {
    background: white;
    color: aqua;
    border: 1px solid #c1c1c1;
    margin-bottom: 15px;
    border-radius: 8px;
}

.unsdg-template .bg_black_accordion_sdgs a {
    font-size: 16px;
    color: black;
    text-decoration: none;
    display: block;
    width: 100%;
    padding: 0.75rem 1.25rem;
}

.unsdg-template .bg_black_accordion_sdgs:hover {
    background-color: var(--sustainability-primary);
}

.unsdg-template .bg_black_accordion_sdgs:hover a {
    color: white;
}

.unsdg-template .history_section_sdgs p,
.unsdg-template .history_section_sdgs li {
    font-size: 16px;
    margin-top: 0;
    line-height: 25px;
    margin-bottom: 1rem;
    font-family: sans-serif;
    color: #4d4d4d;
}



.unsdg-template .history_section_sdgs h2 {
    font-size: 45px !important;
    margin: 20px 0;
    font-weight: 600 !important;
}

.unsdg-template a {
    color: var(--sustainability-primary) !important;
}

.unsdg-template .history_section_sdgs a {
    text-decoration: none;
    color: #333 !important;
}

.unsdg-template .hover_card a {
    border: 1px solid #fff;
    display: block;
    width: 95%;
    padding: 10px 5px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    font-size: 13px;
    margin-top: 15px;
}

.main_card_div.goal_card_last .goal_card {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.main_card_div.goal_card_last .goal_card img {
    height: auto;
    width: 60%;
    margin-top: -25px;
}

.main_card_div.goal_card_last .goal_card a {
    text-decoration: none;
    margin-top: 15px;
    color: #009EDA;
}

.unsdg-template .main_sdgs_div .header_sdgs div {
    text-align: center;
}

.sustainability-layout .about_wrapper h1.mb-20px {
    margin-bottom: 20px !important;
}

.preLineText {
    white-space: pre-line;
}

li.gFormResponseItem:not(:first-child) {
    border-top: 1px solid #dedede;
}

h4.gFormQuestion {
    margin: 0;
    margin-bottom: 15px;
}

li.gFormResponseItem {
    padding: 15px 0;
}

ul.gFormResponses {
    padding: 0;
    margin: 0;
    list-style: none;
}

h4.gFormQuestion .count {
    padding-right: 10px;
    color: var(--sustainability-primary);
}

.gFormAnswer {
    font-size: 15px;
    font-weight: normal;
    padding-left: 30px;
}

.goal-track-item p {
    text-align: left;
    margin: 0;
    color: #333;
}

.goal-track-item {
    width: 85%;
    margin: 0 auto;
}

.__react-tootltip-theme {
    max-width: 250px;
}

.unsdg-template .hover_card a {
    color: #fff !important;
}

.goal-category-box .MuiPaper-elevation1 {
    box-shadow: none;
}

.goal-category-card .card-body:not(.with-pad) {
    padding: 0;
}

.goal-category-card .card-header {
    padding-bottom: 0;
}

/* 
.goal-category-card table thead tr th:first-child{
    text-align: left;
} */

.sustainbility-menu-dropdown {
    background: transparent;
    padding: 0;
    border: none;
    color: inherit;
    height: auto;
    line-height: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 12px;
}

.sustainbility-menu-dropdown span.truncate,
.sustainbility-menu-dropdown svg {
    color: var(--sustainability-black);
    opacity: 0.4;
}

.sustainbility-menu-dropdown svg {
    width: 15px;
    height: 15px;
    padding-top: 6px;
    padding-left: 5px;
}

.sustainbility-menu-dropdown-list {
    background: #fff;
    position: absolute;
    padding: 0;
    border: 1px solid #dedede;
    margin-top: 10px;
    right: 0;
    top: 33px;
}

.sustainbility-menu-dropdown-list div.truncate {
    padding: 0;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.sustainbility-menu-dropdown-list div.truncate:hover {
    background: #eee;
}

.sustainbility-menu-dropdown-main button:not(.sustainbility-menu-dropdown) {
    display: none;
}

.sustainbility-menu-dropdown-list div.truncate a {
    color: var(--sustainability-black);
    opacity: 0.4;
}

.sustainbility-menu-dropdown-list div.truncate a:first-child {
    width: 100%;
}

.sustainbility-menu-dropdown-list div.truncate a.remove {
    width: 50px;
    text-align: center;
    margin: 0 !important;
}

.sustainbility-menu-dropdown-main {
    position: relative;
}

.sustainbility-dropdown-option span:not(.truncate) svg {
    display: none;
}

.sustainbility-dropdown-option span.truncate svg {
    width: 20px;
    height: 20px;
    opacity: 1;
}

.gFormResponseTime {
    margin: 5px 20px;
    font-size: 14px;
    font-weight: normal;
    font-style: italic;
}

tr.dms-table-row.selected {
    background: #e5edea;
    border: none !important;
}

.dms-actions-items ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    margin: 0;
}

.dms-breadcrumbs-main {
    display: flex;
    align-items: center;
}

.dms-actions-items ul li a {
    display: flex;
    padding: 5px;
    min-width: 25px;
    background: #EEEEEE;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    cursor: pointer;
    font-size: 12px;
}

.dms-actions-items ul li a:hover {
    background: #ccc;
    color: #222;
}

/* ---------- new-design-css----------------- */
.new-design {
    font-family: 'poppins';
}

.new-design .justify-end {
    justify-content: flex-end;
}

.new-design .navbar-main-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    box-shadow: 0px 2px 24px 1px rgba(0, 0, 0, 0.07);
    padding: 13px 30px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.new-design .menu-button button {
    background: #1B3548;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    min-width: 35px;
    cursor: pointer;
}

/* -----------sidebar----------- */
.sidebar-new .MuiDrawer-paper {
    width: 300px;
}

.sidebar-new .logo-image {
    padding: 40px;
}

.active .sidebar-new {
    left: 0;
}

.sidebar-new ul {
    padding: 0;
    margin: 0;
}

.sidebar-new ul li {
    list-style: none;
    padding: 0;
    transition: 0.1s all;
}

.sidebar-new ul li:hover {
    background: #1B3548;
    border-radius: 8px;
}

.sidebar-new ul li:hover a {
    color: white !important;
}

.sidebar-new ul li a {
    text-decoration: none;
    color: rgba(3, 2, 41, 0.5);
    display: flex;
    align-items: center;
    column-gap: 16px;
    font-size: 16px;
    transition: 0.3s ease-in-out;
    padding: 15px 25px;
    cursor: pointer;
    font-family: 'Poppins';
}

.sidebar-new .logo-image img {
    width: 110px;
    object-fit: contain;
}

.new-design .cl-white {
    color: #fff;
}

.new-design .fs-24 {
    font-size: 24px;
}

.new-design .bg-gray {
    background: #eee;
}

.new-design .bg-navy {
    background: #1B3548;
    padding-left: 20px;
    height: 84px;
    display: flex;
    align-items: center;
}

.card_header {
    padding: 0 !important;
    border-radius: 14px;
}

.card_header .fs-24 {
    margin: 0 0 !important;
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.card_header .bg-light-grey {
    height: auto;
    padding: 20px 24px;
    border-radius: 14px 14px 0px 0px;
    background: #F5F5F5;
}

.aboutesg-block {
    border-radius: 14px !important;
    border: 1px solid #EFEFEF !important;
    background: #FFF !important;
    box-shadow: 0px 8px 44px 0px rgba(0, 0, 0, 0.03) !important;
}

.aboutesg-block ul.esg-ul {
    padding: 0 18px;
}

.aboutesg-block ul.esg-ul .objective-item {
    margin: 0;
    padding: 30px 0 30px 0;
}

.aboutesg-block ul.esg-ul .objective-item a {
    gap: 12px;
    align-items: center;
}

.aboutesg-block ul.esg-ul .objective-item a h6 {
    margin: 0 0 0 0;
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.aboutesg-block ul.esg-ul .objective-item a .objective-title-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
}

.more-context svg.MuiSvgIcon-root {
    font-size: 15px !important;
    color: #888888;
}

.aboutesg-block ul.esg-ul .objective-item a .objective-title-detail svg.MuiSvgIcon-root {
    color: #888888;
    font-size: 16px;
}

.bg-light-grey .fs-24 {
    margin: 0 0 0 0;
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card_header .main-wrapper {
    padding: 24px;
    border-radius: 0 0 14px 14px;
    border: 1px solid #EFEFEF;
    background: #FFF;
    box-shadow: 0px 8px 44px 0px rgba(0, 0, 0, 0.03);
}

.card_header .main-wrapper .overview-div {
    /* border-radius: 14px;
    border: 1px solid #ECECEC; */
    background: #FFF;
    padding: 0;
    margin: 5px !important;
}

.card_header .main-wrapper .overview-div .tabs h4 {
    margin: 0 0 !important;
    border-radius: 14px 14px 0px 0px;
}

.MuiGrid-root.tabs.MuiGrid-item {}

.report_actions {
    padding: 5px;
    border: 1px solid black;
    border-radius: 50%;
    margin: 10px 5px 5px 5px;
}

.card_header .main-wrapper .overview-div .overview-topic-block {
    background: #FFF;
    padding: 16px;
    border-radius: 0px 0px 14px 14px;
    /* border: 1px solid #ECECEC; */
}

.new-design .bg-light-grey {
    background: #eee;
    padding: 20px 24px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: black;
    display: flex;
    align-items: center;
}

.bg-light-grey .fs-24 {
    margin: 0 0 0 0;
}

.new-design .container {
    max-width: 100%;
    margin: auto;
}

.new-design .text-center {
    text-align: center;
}

.new-design .img-full {
    width: 100%;
    height: auto;
}

.new-design .col-50 {
    flex: 0 0 48.7%;
}

.new-design .overview-col-50 {

    border: 1px solid #d5d0d0;
    border-radius: 10px;
}

.new-design .ds-flex {
    display: flex;
}

.new-design .item-center {
    align-items: center;
}

.new-design .item-between {
    justify-content: space-between;
}

.new-design .center {
    justify-content: center;
}

.new-design .esg-ul {
    margin: 0;
    padding: 0;
}

.new-design .esg-ul li {
    list-style-type: none;
    margin: 0 25px;
    border-bottom: 1px solid rgb(0, 0, 0, .1);
    padding: 20px 0;
}

.new-design .esg-ul h6 {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    margin: 0 0 0 13px;
}

.new-design .esg-ul small {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    opacity: 0.4;
    margin: 0 0 0 13px;
}

/* ------dashboard------ */

.heading.dashboard-heading {
    margin: 0px !important;
}

.heading.dashboard-heading .heading-text {
    margin: 10px !important;
}

.new-design .heading .heading-text {
    font-size: 34px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--sustainability-primary);
    margin: 34px 0;
}

.new-design .heading {
    position: relative;
    margin-top: 60px;
}

.new-design .heading .heading-text::before {
    content: "";
    position: absolute;
    display: block;
    width: 40%;
    height: 4px;
    top: 50%;
    background: #000;
    opacity: 0.06;
}

.new-design .heading .heading-text::after {
    content: "";
    position: absolute;
    display: block;
    width: 40%;
    height: 4px;
    right: 0;
    top: 50%;
    background: #000;
    opacity: 0.06;
}

.new-design .more-about-esg {
    margin: 40px 0;
    flex-wrap: wrap;
    row-gap: 20px;
}

.new-design .navy-btn {
    padding: 14px 26px;
    border-radius: 6px;
    border: 1px solid #1B3548;
    background: #1B3548;
    color: white;
    font-size: 16px;
    min-width: 189px;
    display: block;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

.new-design .white-btn {
    padding: 14px 26px;
    border-radius: 6px;
    border: 1px solid #1B3548;
    background: white;
    color: #1B3548;
    font-size: 16px;
    min-width: 189px;
    display: block;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

.new-design .two-btns {
    display: flex;
    align-items: center;
    column-gap: 12px;
    justify-content: flex-end;
}

.new-design .blank-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.new-design .blank-page p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    opacity: 0.6;
    margin-top: 0;
}

.new-design .blank-page h2 {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
}

/* ----------objective heading---- */

.new-design .back-heading {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-design .back-button button {
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 18px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: #357E87;
}

.new-design .heading-objective h2 {
    font-family: 'Pangram';
    font-size: 34px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0em;
    color: var(--sustainability-primary);
    margin: 0;
}

.new-design .heading-track {
    color: #357E87;
    font-family: "Pangram";
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}



/* --------navy-table----------------- */
.new-design .navy-table table {
    width: 100%;
    border-spacing: 0;
    margin-top: 20px !important;
}

.new-design .navy-table table thead {
    background: #F5F5F5;
    /* background: var(--sustainability-primary); */
    color: #000;
    height: 56px;
}

.new-design .blue-table table thead {
    background: #F5F5F5;
    /* background: var(--sustainability-primary);  */
}

.blue-table tr th {
    text-align: left;
}

.blue-table table {
    table-layout: fixed;
}

.goal-images .new-design .navy-table .table-heading {
    font-size: 14px;
    opacity: 0.5;
}

.new-design .round-numbers-box .social button {
    background: #6D55AB !important;
    border: 2px solid rgba(255, 255, 255, 0.40);
}

.new-design .round-numbers-box .governance button {
    background: #F04594 !important;
    border: 2px solid rgba(255, 255, 255, 0.40);
}

.new-design .round-numbers-box .environment button {
    background: #3F91CE !important;
    border: 2px solid rgba(255, 255, 255, 0.40) !important;
}

.new-design .navy-table table tr td {
    border-bottom: 1px solid #E0E0E0;
}

.new-design .navy-table table tr:last-child td {
    border: none;
}

.new-design .navy-table table thead tr th:first-child {
    padding-left: 20px;
}

.new-design .navy-table table tbody tr td:first-child {
    padding-left: 20px;
}

.new-design .navy-table table tbody tr td {
    padding: 25px 10px;
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.objective-img-fivegrid {
    gap: 8px !important;
}

.objective-img-fivegrid span.more-context {
    font-size: 12px !important;
}

.objective-img-fivegrid .goal-images {
    max-width: 100% !important;
}

.objective-img-fivegrid .goal-images {
    width: calc(25% - 20px);
}

.new-design .round-numbers-box .have-objectives {
    flex: 0 0 38px;
    max-width: 38px;
}

.new-design .round-numbers-box .have-objectives button {
    width: 38px;
    height: 38px;
}

.priority-major {
    background-color: #F55F44 !important;
}

.priority-significant {
    background-color: #B2D349 !important;
}

.priority-moderate {
    background-color: #F7B800 !important;
}

.priority-negligible {
    background-color: #3F91CE !important;
}

span.material-topics-priority {
    padding: 5px 15px;
    border-radius: 20px;
}

.popup-table tr td:not(:first-child) {
    text-align: center;
}

.round-number-subject.round-number-item.have-objectives.environment {
    text-align: left;
}

.round-number-subject.round-number-item.have-objectives.environment button {
    margin: 5px 10px;
}

.popup-table tr {
    border: 1px solid #E0E0E0
}

.sdg-popup .topics {
    font-weight: 900;
}

.sdg-popup img {
    margin-right: 30px !important;
}

.sdg-popup {
    margin: 10px;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    color: white;
}

.more-context {
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: #F5F5F5;
    padding: 6px 14px;
    border-radius: 34px;
    cursor: pointer;
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 5px;
}

.new-design .navy-table input {
    max-width: 131px;
    padding: 12px;
    position: relative;
    border: 1px solid #dfdfdf;
    appearance: none;
}

.new-design ::-webkit-outer-spin-button,
.new-design ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.new-design .navy-table span {
    position: relative;
}

.new-design .navy-table span::after {
    content: "%";
    display: block;
    position: absolute;
    color: #BBBBBB;
    right: 15px;
    top: 0;
}

.new-design .round-numbers-box {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;

}

.new-design .round-number {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #FFFFFF66;
    cursor: pointer;
}

.new-design .pink {
    background-color: #F04594;
}

.new-design .blue {
    background-color: #3F91CE;
}

.new-design .purple {
    background-color: #6D55AB;
}

/* -------unsdg----------- */

.new-design .tabs {
    display: flex;
    align-items: center;
    background: #f1f1f1;
    border: 6px solid #f1f1f1;
    border-radius: 6px;
    margin: 20px 0;
    column-gap: 20px;
    padding: 0 5px;
}

.tabs.tabs-dashboard {
    margin-top: 0px !important;
}

.new-design .tabs .tab-head-options {
    color: black;
    flex: 1 1 32%;
    padding: 21px;
    font-size: 18px;
    border-radius: 4px;
    text-align: center;
    -webkit-appearance: none;
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    background: #ffffff;
}

.btn-add {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1px;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    background-color: #ffffff;
    border: none
}

.btn-add span {
    background-color: #1B3548;
    color: white;
    border-radius: 50%;
    padding: 5px;
    margin: 5px;
}

.table-delete-icon {
    cursor: pointer;
    background: inherit;
    border: none
}

.chat-panel-header-with-detete-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btn-red {
    background: red !important;
    color: #ffffff !important;
    border-color: red !important;
}

.new-design .tabs input::before {
    content: attr(label);
    display: block;
}

.new-design .tabs .tab-head-options {
    transition: .4s ease;
}

.new-design .tabs .tab-head-options.active,
.new-design .tabs .tab-head-options:hover {
    background: #009EDA;
    color: white;
}

.tab-head-options.tab-dashboard.active {
    background-color: #1B3548 !important;
    color: white;
}

.performance-main {
    display: flex;
    gap: 24px;
}

.performance-main .dashboard-leftbar {
    flex: 0 0 290px;
}

.performance-main .dashboard-leftbar .kpi-count-div {
    margin: 0 0 18px 0 !important;
    padding: 24px !important;
}

.performance-main .dashboard-leftbar .kpi-count-div h2 {
    color: #F5F5F5;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 0 8px 0;
}

.performance-main .dashboard-leftbar .kpi-count-div span {
    color: #FFF;
    font-family: Poppins;
    font-size: 74px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 88.8px */
}

.performance-main .status-div {
    border-radius: 14px;
    border: 1px solid #EFEFEF;
    background: #FFF;
    box-shadow: 0px 8px 44px 0px rgba(0, 0, 0, 0.03);
}

.topic-list.navy-table table thead tr th .allimg-flex {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.topic-list.navy-table table thead tr th .allimg-flex img.hover-raised-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.performance-main .status-div h2 {
    margin: 0 0 18px 0;
    color: rgb(0 0 0 / 60%);
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.topic-list.navy-table table thead tr th {
    padding: 25px 10px;
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
}

.performance-main .status-div h4 {
    margin: 0 0 18px 0;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.performance-main .status-div h4:last-child {
    margin: 0 !important;
}

.objective-header {
    display: flex;
}

.objective-header img {
    width: 50px !important;
    height: 50px !important;
    margin: 5px !important;
}

.kpi-count-div {
    background: #1B3548;
    color: white;
    text-align: center;
    padding: 15px;
    border-radius: 15px;
    margin: 0px 10px 10px 0px !important;
}

.status-div {
    background: #ffffff;
    border-radius: 15px;
    padding: 20px !important;
}

.status-div h4 {
    display: flex;
}

.new-design .color-purple .tabs input:checked {
    background: #6D55AB;
    color: white;
}

.new-design .color-pink input:checked {
    background: #F04594;
    color: white;
}

.new-design .main-unsdg-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;
    background: #EEF8FF;
    gap: 20px;
    flex-wrap: wrap;
}

.new-design .color-pink .main-unsdg-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;
    background: #FFF1F7;
    gap: 20px;
    flex-wrap: wrap;
}

.main-unsdg-info.color-pink {
    background: #FFF1F7;
}

.new-design .color-purple .main-unsdg-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;
    background: #EDE6FF;
    gap: 20px;
    flex-wrap: wrap;
}

.main-unsdg-info.color-purple {
    background: #EDE6FF;
}

.new-design .main-unsdg-info .grey-li ul,
.new-design .main-unsdg-info .blue-li ul {
    padding: 0;
    margin: 0;
}

.new-design .main-unsdg-info .grey-li li {
    list-style: none;
    color: #666;
    font-weight: normal;
    padding: 4px 0;
    font-size: 15px;
}

.new-design .main-unsdg-info .blue-li li {
    list-style: none;
    color: #3F91CE;
    font-weight: 600;
    padding: 4px 0;
}

.main-unsdg-info.color-purple .blue-li li {
    list-style: none;
    color: #6D55AB;
    font-weight: 600;
}

.main-unsdg-info.color-pink .blue-li li {
    list-style: none;
    color: #F04594;
    font-weight: 600;
}

.new-design .main-unsdg-info h2 {
    color: #3F91CE;
}

.main-unsdg-info.color-purple h2 {
    color: #6D55AB;
}

.main-unsdg-info.color-pink h2 {
    color: #F04594;
}

.new-design .right-side input {
    appearance: none;
}

.new-design .unsdg-data-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background: #f1f1f1;
    border-bottom: 1px solid #dfdfdf;
    flex-wrap: wrap;
    gap: 15px;
}

.new-design .tag,
.new-design .goal-tag-option {
    padding: 7px 14px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    background-color: white;
    font-size: 13.5px;
    cursor: pointer;
}

.new-design .left-side span {
    font-size: 14px;
    color: #3F91CE;
    padding-left: 8px;

}

.new-design .color-pink .left-side span {
    font-size: 14px;
    color: #F04594;
    padding-left: 8px;
}

.new-design .color-purple .left-side span {
    font-size: 14px;
    color: #6D55AB;
    padding-left: 8px;
}

.new-design .right-side {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
    flex: 1 1 65%;
    position: relative;
}

.new-design .right-side .tag::before {
    content: attr(label);
    display: block;
}

.new-design .right-side .tag:checked {
    background: #3F91CE;
    color: white;
    border: 1px solid transparent;
}

.new-design .color-pink .right-side .tag:checked {
    background: #F04594;
    color: white;
    border: 1px solid transparent;
}

.new-design .color-purple .right-side .tag:checked {
    background: #6D55AB;
    color: white;
    border: 1px solid transparent;
}

.new-design .right-side .add-tag {
    background: transparent;
    border: none;
    color: #3f91ce;
    font-size: 14px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;
}

.new-design .color-pink .right-side .add-tag {
    background: transparent;
    border: none;
    color: #F04594;
    font-size: 14px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;
}

.new-design .color-purple .right-side .add-tag {
    background: transparent;
    border: none;
    color: #6D55AB;
    font-size: 14px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;
}

.new-design .left-side {
    display: flex;
    align-items: center;
    gap: 14px;
    flex: 1 1 30%;
    flex-wrap: wrap;
}

.new-design .left-side h4 {
    margin: 0;
    font-size: 16px;
    color: #bbbbbb;
    font-weight: 500;
}

.new-design .left-side p {
    margin: 0;
    font-size: 16px;
}

.new-design .left-side img {
    min-width: 48px;
    height: 48px;
}

.new-design .right-side::before {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 76px;
    background-color: #E0E0E0;
    left: -20px;
}

.new-design .scrollable-box {
    max-height: 575px;
    overflow-y: auto;
    margin: 25px 0;
}

/* ---------------------new grid----------- */
.new-design .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 170px);
    grid-gap: 1px;
    justify-content: flex-end;
}

.new-design .grid-item {
    padding: 15px;
    width: 140px;
    height: 140px;
    background-color: #6C9BA1;
    /* You can change this shade of blue */
}

.new-design .grid-item:nth-child(2) {
    background-color: #468087;
}

.new-design .grid-item:nth-child(3) {
    background-color: #2A6C74;
}

.new-design .grid-item:nth-child(4) {
    background-color: #105A63;
}

.new-design .grid-item:nth-child(5) {
    background-color: #91B6BB;
}

.new-design .grid-item:nth-child(6) {
    background-color: #6C9BA1;
}

.new-design .grid-item:nth-child(7) {
    background-color: #468087;
}

.new-design .grid-item:nth-child(8) {
    background-color: #2A6C74;
}

.new-design .grid-item:nth-child(9) {
    background-color: #B8D3D6;
}

.new-design .grid-item:nth-child(10) {
    background-color: #91B6BB;
}

.new-design .grid-item:nth-child(11) {
    background-color: #6C9BA1;
}

.new-design .grid-item:nth-child(12) {
    background-color: #468087;
}

.new-design .grid-item:nth-child(13) {
    background-color: #DBE9EB;
}

.new-design .grid-item:nth-child(14) {
    background-color: #B8D3D6;
}

.new-design .grid-item:nth-child(15) {
    background-color: #91B6BB;
}

.new-design .grid-item:nth-child(16) {
    background-color: #6C9BA1;
}

.new-design .chart-names {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.new-design .down-side {

    margin-left: auto;
}

.new-design .down-side .right-bar {
    color: #357E87;
    font-size: 18px;
    font-weight: 500;
    position: relative;
}

.new-design .down-side .right-bar::before {
    position: absolute;
    content: "";
    display: block;
    width: 50%;
    height: 1px;
    background-color: #357E87;
    right: 0;
    top: 50%;
}

.new-design .up-side .right-bar {
    color: #357E87;
    font-size: 18px;
    font-weight: 500;
    position: relative;
}

.new-design .up-side .right-bar::before {
    position: absolute;
    content: "";
    display: block;
    width: 40%;
    height: 1px;
    background-color: #357E87;
    right: 0;
    top: 50%;
}

.new-design .main-grid-box {
    position: relative !important;
    width: fit-content;
    height: fit-content;
}

.new-design .up-side {
    position: absolute;
    transform: rotate(-90deg);
    width: 100%;
    top: 0;
    bottom: 89px;
    left: -83px;
}

.new-design .m-auto {
    margin: auto;
}

/* ------------------descrimination-screen-------------------- */
.new-design .main-discrimination-box {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 34px;
    flex-wrap: wrap;
    gap: 50px;
}

.new-design .numbering {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
}

.new-design .numbering span {
    font-weight: 500;
    color: #357E87;
    font-size: 24px;
}

.new-design .numbering+p {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.new-design .left-side-box .input-group-discrimination p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.new-design .left-side-box {
    flex: 1 1 35%
}

.new-design .right-side-box {
    flex: 1 1 60%;
    display: flex;
    justify-content: end;
    position: relative;
}

.new-design .left-side-box .relevance {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #aaaaaa;
}

.new-design .select-padded select {
    border: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    min-width: 110px;
}

.new-design .select-padded {
    padding: 6px 12px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    background-color: white;
}

.new-design .input-group-discrimination input {
    width: 100%;
}

.new-design .buttons-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 44px;
}

select:focus-visible {
    outline: none;
    box-shadow: none;
}

.new-design input[type='range']::-webkit-slider-runnable-track {
    color: #1B3548;
}



/* -----------------set objective------------------------- */
.new-design .set-objective {
    position: fixed;
    right: 0;
    top: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    border-radius: 6px 0px 0px 6px !important;
    padding: 17px 29px;
}

.new-design .tabs-setobjective {
    padding: 30px 0;
}

.new-design .right-side-panel {
    width: 680px;
    padding: 24px 34px;
    background-color: white;
    z-index: 20;
    height: 100dvh;
    right: 0;
    position: absolute;
    backdrop-filter: blur(2px);
    overflow: auto;
}

.new-design .right-side-panel .tabs input:checked {
    background: #1B3548;
    color: white;
    margin: 0;
}

.new-design .right-side-panel .tabs {
    border: none;
}

.new-design .right-side-panel-wrapper {
    width: 100vw;
    height: 100dvh;
    top: 0;
    left: 200%;
    position: fixed;
    background-color: rgb(0 0 0 / 40%);
    z-index: 3;
    transition: 0.3s;
}

.topic-backgroud {
    background-color: white;
    padding: 10px;
}

.rightpanel .right-side-panel-wrapper {
    left: 0;
}

.new-design .set-objective-content {
    padding: 2px;
    overflow-y: auto;
}

.new-design .set-objective-content .kpi-box {
    display: flex;
    align-items: start;
    flex-direction: column;
    row-gap: 12px;
    margin: 20px 0;
}

.new-design .set-objective-content input,
.new-design .set-objective-content select {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    border: 1px solid #D9D9D9;
}

.new-design .set-objective-content select {
    color: #a5a5a5;
}

.new-design .set-objective-content .tags-affected .tag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    border-bottom: 1px solid #E0E0E0;
}

.new-design .set-objective-content .tags-affected .tags-box {
    background-color: #fff;
}

.new-design .set-objective-content .tags-affected .tag label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
}

.new-design .set-objective-content .tags-affected .tag .round-number {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 28px;
    height: 28px;
    padding: none;
    color: white;
    background-color: #009EDA;
}

.new-design .add-more-btn {
    border: none;
    background-color: transparent;
    color: #1B3548;
    font-size: 14px;
    font-weight: 700;
    column-gap: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* ----------tabs-checks-------- */

.new-design .tabs-setobjective:has(#viewobjective:checked) .view-objective-content {
    display: block;
}

.new-design .tabs-setobjective:has(#setobjective:checked) .set-objective-content {
    display: block;
}

.steps.text-center {
    justify-content: center;
}

.steps p span {
    width: 1px;
    border-color: #eee;
    height: 30px;
    margin: 0 10px;
}

a.tab-head-options.color-purple.active,
a.tab-head-options.color-purple:hover {
    background: #6D55AB !important;
}

.overview_dashboard .tabs {
    margin: 0px !important;
    background: #ffffff;
    padding: 0px !important;
    border: 0px !important;
}

.overview-topics {
    border-bottom: 1px solid #EEEEEE;
    padding: 10px 0px 10px 0 !important;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    text-align: left;
}

.overview-topics {
    border-bottom: 1px solid #EEEEEE;
    padding: 10px 0px 10px 0 !important;
}

.card_header .main-wrapper .overview-div .overview-topic-block .overview-topics:last-child,
.card_header .main-wrapper .overview-div .overview-topic-block .overview-topics:nth-last-child(2) {
    border: none;
    padding-bottom: 0 !important;
}

.overview-topic-block.color-purple {
    background: #8670C1 !important;
}

.overview-topic-block.color-blue {
    background: #52A4E1 !important;
}

.overview-topic-block.color-pink {
    background: #FB59A5 !important;
}

span.topic-span {
    padding: 4px;
    color: white;
    border-radius: 5px;
    margin: 5px;
}

.color-purple-dark {
    background: #7761B2;
}

.color-blue-dark {
    background: #3F91CE;
}

.topic-grid {
    padding: 0px !important;
}

.color-pink-dark {
    background: #F92D8E;
}

.color-purple-light {
    background: #8670C1 !important;
    padding: 20px 20px 0px 20px !important;
    justify-content: left !important;
    font-weight: 900 !important;
    font-size: 25px !important;
}

.card_header .main-wrapper .overview-div .MuiGrid-root.MuiGrid-direction-xs-column {
    height: 100%;
    display: block;
}

.custom-grid-hequal {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
}

.aboutesg-block ul.esg-ul .objective-item a h6,
.aboutesg-block ul.esg-ul .objective-item a svg {
    transition: .3s ease;
}

.aboutesg-block ul.esg-ul .objective-item:hover a h6 {
    color: #0a97da;
}

.aboutesg-block ul.esg-ul .objective-item:hover a svg {
    transform: translate(10px, 0px) !important;
}

.custom-grid-hequal .tabs {
    margin: 0 0 0;
}

.card_header .main-wrapper .overview-div .overview-topic-block {
    height: calc(100% - 41px);
    display: block;
}

.card_header .main-wrapper .overview-div .overview-topic-block span.topic-span {
    display: inline-block;
}

.color-blue-light {
    background: #52A4E1 !important;
    padding: 20px 20px 0px 20px !important;
    justify-content: left !important;
    font-weight: 900 !important;
    font-size: 25px !important;
}

.color-pink-light {
    background: #FB59A5 !important;
    padding: 20px 20px 0px 20px !important;
    justify-content: left !important;
    font-weight: 900 !important;
    font-size: 25px !important;
}

.esg-unew-designl-grid {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    gap: 12px;
    padding: 12px;
}

.overview-topics span {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    text-align: left;
}

h4.tab-head-options.active {

    cursor: initial;
}

h4.tab-head-options.color-purple.active {
    background: #6D55AB !important;
    cursor: initial;
}


h4.tab-head-options.color-pink.active {
    background: #F04594 !important;
    cursor: initial;
}

a.tab-head-options.color-pink.active,
a.tab-head-options.color-pink:hover {
    background: #F04594 !important;
}

.goal-tag-option.active {
    background: #3F91CE !important;
    color: #fff !important;
}

.color-purple .goal-tag-option.active {
    background: #6D55AB !important;
}

.color-pink .goal-tag-option.active {
    background: #F04594 !important;
}

.objective-box-info p {
    margin: 5px 0;
}

.objective-box-info p.title {
    font-weight: 600;
    color: #000;
    font-size: 15px;
}

.objective-box-info ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.objective-box-info p.objective strong {
    text-transform: capitalize;
}

.objective-box-info p.objective strong {
    color: #3F91CE !important;
}

.objective-box-info.social p.objective strong {
    color: #6D55AB !important;
}

.objective-box-info.governance p.objective strong {
    color: #F04594 !important;
}

.round-number-item button {
    height: 30px;
    width: 30px;
    background: #3F91CE !important;
    color: #fff !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
}

.round-number-item.social button {
    background: #6D55AB !important;
}

.round-number-item.governance button {
    background: #F04594 !important;
}

.round-number-item {
    padding: 0 !important;
}

.objective-box-info p.objective .number {
    margin-right: 5px;
    height: 25px;
    width: 25px;
    background: #3F91CE !important;
    color: #fff !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.objective-box-info.social p.objective .number {
    background: #6D55AB !important;
}

.objective-box-info.governance p.objective .number {
    background: #F04594 !important;
}

.mx-auto {
    margin: 0 auto;
}

.right-side-panel-wrapper.open {
    left: 0;
}

.tabs-setobjective .tabs a {
    width: 50%;
    display: block;
    text-align: center;
    padding: 15px;
    cursor: pointer;
}

.tabs-setobjective .tabs a.active {
    background: var(--sustainability-primary);
    color: #fff;
}

.set-objective-close-btn {
    margin-top: 30px;
    color: #999;
    display: inline-block;
    padding: 8px 10px;
    background: #f3f3f3;
    cursor: pointer;
}

.tags-box {
    display: flex;
    flex-wrap: wrap;
}

.tags-box .tag {
    flex: 0 0 50%;
    margin: 0;
    padding: 0;
}

.tags-box .tag label {
    padding: 10px 0;
    cursor: pointer;
}

.kpi-item {
    width: 100%;
}

.kpi-item-fields {
    display: flex;
    width: 100%;
    align-items: center;
}

.kpi-item-fields a.remove-item {
    background: #1A3547;
    color: #fff;
    margin-left: 10px;
    width: 38px;
    text-align: center;
    font-size: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 6px;
    cursor: pointer;
    opacity: 0.9;
}

.kpi-item-fields a.remove-item:hover {
    opacity: 1;
}

.kpi-item-fields a.remove-item svg {
    height: 20px;
    width: 20px;
}

.navy-table.blue-table {
    width: 100%;
    background: #fff;
}

.new-design .navy-table span::after {
    display: none;
    content: "";
}

.sidebar-new {
    border-right: 1px solid #eee;
}

.sidebar-new.show {
    left: 0;
    z-index: 999;
}

.navbar_links.navbar_links-dashboard a img {
    height: 30px;
    width: auto;
}

.navbar_links.navbar_links-dashboard a {
    opacity: 1;
    margin-left: -65px !important;
}

.navbar_links.navbar_links-dashboard {
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
}

main.new-design.dashboard .go_back_button img {
    display: none;
}

main.new-design {
    width: 100%;
}

.hover-raised-image {
    transition: transform 0.3s ease-in-out;
}

.hover-raised-image:hover {
    transform: translateY(-5px);
    /* Adjust the value to change the raise effect */
}

.esg-ul img {
    /* height: 50px;
    width: 50px; */
    height: 100px;
    width: 100px;
    padding: 5px 10px;
    border-radius: 20px;
}

.esg-goal-li {
    align-items: center;
}

.unsdg-badge-item {
    height: 30px;
    width: 30px;
    background: #333;
    display: inline-block;
    margin-right: 10px;
}

span.environments.unsdg-badge-item {
    background: #3F91CE;
}

span.social.unsdg-badge-item {
    background: #6D55AB;
}

span.governance.unsdg-badge-item {
    background: #F04594;
}

.info-items ul {
    height: 100%;
    list-style: none;
}

.info-items ul li {
    padding: 25px 0;
    display: flex;
    align-items: center;
}

.chart-elem {
    display: flex;
    align-items: center;
    background: #fff;
}

.chart-elem .chart-elem-inner div[dir="ltr"] {
    width: 100% !important;
    margin: 0 auto !important;
}

.chart-elem .chart-elem-inner div[dir="ltr"] svg {
    width: 75% !important;
    height: 100% !important;
}

.chart-elem .info-chart {
    text-align: left;
}

.chart-elem .info-chart {
    width: 100%;
}

.buttons-chart {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.btn-active {
    background: rgb(33, 32, 32) !important;
    color: white !important;
}

.buttons-chart button {
    padding: 10px 12px;
    border: none;
    border-radius: 4px;
    background-color: #dfdfdf;
    color: black;
}

.chart-elem .info-items {
    width: 50%;
}

div {}

div {}

.chart-elem .info-chart .google-visualization-tooltip {
    border-radius: 10px !important;
    text-align: left !important;
    width: 400px !important;
    z-index: 999 !important;
    background: #ffffff !important;
    cursor: pointer !important;
}

.tooltip-chart {
    padding: 10px;
    border-radius: 10px;
    background: #ffffff !important;
}

.tooltip-chart h2 {
    margin: 0 0 0 0 !important;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}

.tooltip-chart p {
    margin: 0 0 0 0;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
}

.tooltip-chart .row {
    justify-content: flex-start !important;
    column-gap: 20px;
}

.tooltip-chart .row h2 {
    font-size: 16px;
    margin: 0 0 0 0;
}

.tooltip-chart .row p {
    margin: 0;
    font-size: 14px;
}

.tooltip-chart .row .col-4:nth-child(2) {
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    padding: 0 20px;
}

.objective-item a {
    display: flex;
    cursor: pointer;
    width: 100%;
}

.topic-list.navy-table {
    border-radius: 14px;
    border: 1px solid #EFEFEF;
    background: #FFF;
    /* box-shadow: 0px 8px 44px 0px; */
}

.heading-sdg img {
    margin: 0px 5px;
}

.heading-sdg {
    text-align: center;
    padding: 10px;
}

.priority-count span {
    margin: 5px
}

.new-design .topic-list.navy-table table {
    margin-top: 0px !important;
}

.flexbox-title {
    display: flex;
    align-items: flex-start;
    column-gap: 10px;
}

.newui-table {
    table-layout: fixed;
}

.newui-table td {
    text-align: left;
}

.newui-table tr td img {
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 3px 0;
}

td.track-kpi-links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

ul.dropbar {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: right;
}

ul.dropbar li:hover .dropcontent {
    opacity: 1;
    visibility: visible;
    cursor: pointer;
}

ul.dropbar li .dropcontent button.table-delete-icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    padding: 10px;
    border-bottom: 1px solid #E0E0E0;
    color: #1B3548;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 10px;
    width: 140px;
}

ul.dropbar li .dropcontent button.table-delete-icon svg {
    font-size: 18px;
}

ul.dropbar li .dropcontent button.table-delete-icon:first-child {
    color: #FF2F48;
}

ul.dropbar li {
    position: relative;
}

.mb-20 {
    margin-bottom: 10px;
}

.addkpimodal label {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    display: inline-block;
}

.addkpimodal input,
.addkpimodal select {
    border-radius: 4px;
    border: 1.134px solid #D9D9D9;
    background: #FFF;
    padding: 15px 24px;
    color: #000000;
    font-family: Poppins;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.addkpimodal input::placeholder {
    color: #B6B6B6;
}

.addkpimodal .btn-sustainability {
    width: 100%;
}

ul.dropbar li button.dotbnt {
    background: transparent;
    border: none;
    font-size: 30px;
    color: gray;
}

ul.dropbar li .dropcontent {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: .3s ease;
    background: #ffffff;
    z-index: 3;
    border-radius: 10px;
    border: 1px solid #E7E7E7;
    background: #FFF;
    box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.06);
}

.newui-table tr td .priority-count {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    column-gap: 4px;
}

.newui-table tr td .priority-count span.material-topics-priority {
    margin: 0 0 0 0;
    padding: 2px 10px 2px 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 2px;
}

.newui-table tr td .priority-count span a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flexbox-title .back-button,
.flexbox-title .empty {
    flex: 0 0 20%;
}

.flexbox-title .centerpart .dividebar {
    text-align: center;
    margin: 10px 0 20px 0;
}

.flexbox-title .centerpart {
    flex: 0 0 60%;
    max-width: 60%;
}

.flexbox-title .centerpart .heading-objective h2 {
    color: #1B3548;
    text-align: center;
    font-family: Pangram;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 115%;
    /* 34.5px */
}

.kpi-head-tabs {
    padding-top: 35px;
}

.kpi-head-tabs h2 {
    text-align: center;
}

.kpi-head-tabs .back-button {
    /* position: absolute; */
    color: var(--sustainability-primary);
}

.kpi-head-tabs .back-button button {
    color: var(--sustainability-primary);
}

.kpi-head-tabs .back-button button svg {
    border: 1px solid black;
    border-radius: 50%;
    padding: 5px;
}

.heading-objective {
    padding-top: 0;
    text-align: center;
}

.track-kpi-link {
    padding: 0 !important;
}

.track-kpi-link a {
    cursor: pointer;
    padding: 25px 20px !important;
    display: block;
}

.light-color {
    color: #999;
}

.mb-0px {
    margin-bottom: 0;
}

.sus-form-field {
    width: 100%;
}

.sus-form-field label {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    font-size: 13px;
}

.sus-form-field .form-control {
    padding: 15px 10px;
    display: block;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 14px;
}

.kpi-top-info {
    padding: 45px 0;
}

.kpi-top-info p {
    margin: 5px 0;
}

.kpi-top-info .space-t {
    margin-top: 25px;
}

.sus-form-field {
    margin: 7px 0;
}

.min350 .MuiDialog-paper {
    min-height: 350px !important;
}

.add-more-trigger {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    margin: 15px 0;
    color: grey;
}

.css-1fdsijx-ValueContainer {
    padding: 10px !important;
}

.input-big {
    padding: 15px 10px !important;
    display: block;
    width: -webkit-fill-available;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    font-size: 14px;
}

.chart-pdd {
    padding: 20px;
    background-color: white;
    border-radius: 12px;
}

.my-3 {
    padding: 0.5rem 0 !important;
}

.month-picker {
    position: relative;
}

.month-picker>.rmp-container {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 100;
    top: 1px;
    left: -10000px;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
}

@media screen and (max-width: 767px) {
    .month-picker>.rmp-container {
        position: fixed;
        top: 0;
        left: -10000px;
        width: 100%;
        height: 100%;
        transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
    }
}

.month-picker>.rmp-container.rmp-table {
    display: table;
}

.month-picker>.rmp-container.show {
    left: 0;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.month-picker>.rmp-container.show .rmp-overlay {
    left: 0;
}

.month-picker>.rmp-container .rmp-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 9;
    top: 0;
    left: -10000px;
    opacity: 1;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: opacity;
    transform: translateZ(0);
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

@media screen and (max-width: 767px) {
    .month-picker>.rmp-container .rmp-overlay {
        background-color: rgba(0, 0, 0, 0.25);
    }
}

.month-picker>.rmp-container .rmp-cell {
    display: table-cell;
    vertical-align: middle;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.sus-report-title {
    /* margin: 25px 10px; */
    display: flex;
    align-items: center;
}

.sustainability-layout .about_wrapper .sus-report-title h1 {
    margin: 0 !important;
}

.sus-report-title .sustainbility-menu-dropdown-main {
    margin-right: 0;
    margin-left: auto;
}

.sus-report-title .sustainbility-menu-dropdown-list {
    z-index: 99;
}

.sus-report-title .sustainbility-menu-dropdown-main svg {
    padding: 0;
    width: 28px !important;
    height: 28px !important;
    margin-top: 4px !important;
    color: #000 !important;
    fill: #000 !important;
    opacity: 1 !important;
}

.sus-report-title .sustainbility-menu-dropdown-list a {
    padding: 10px;
    color: #333 !important;
    opacity: 1 !important;
    border-bottom: 1px solid #ccc;
    outline: none !important;
    text-decoration: none !important;
}

.sustainability-layout .about_wrapper .sus-report-title h1 {
    margin-right: 20px !important;
}

.sustainbility-menu-dropdown-list div.truncate:last-child a {
    /* padding-top: 0; */
    border-bottom: 0;
}

.sus-report-title .sustainbility-menu-dropdown span.truncate,
.sus-report-title .sustainbility-menu-dropdown svg {
    opacity: 1 !important;
}

@media screen and (max-width: 767px) {
    .month-picker>.rmp-container .rmp-cell {
        vertical-align: bottom;
    }
}

.month-picker>.rmp-container .rmp-popup {
    position: absolute;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
    margin: 0 auto;
    z-index: 10;
    font-size: 1.2rem;
    opacity: 0;
    border-radius: 3px;
    padding: 0.4rem;
    box-sizing: content-box;
}

@media screen and (max-width: 767px) {
    .month-picker>.rmp-container .rmp-popup {
        box-sizing: border-box;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        height: 14.4rem;
        transform: translate3d(0, 14.4rem, 0);
    }

    .month-picker>.rmp-container .rmp-popup.range {
        height: 28rem;
        transform: translate3d(0, 28rem, 0);
    }

    .month-picker>.rmp-container .rmp-popup.range .rmp-pad {
        margin-top: 0.4rem;
    }

    .month-picker>.rmp-container .rmp-popup.range .rmp-pad:first-of-type {
        margin-top: 0;
    }
}

@media screen and (min-width: 768px) {
    .month-picker>.rmp-container .rmp-popup {
        transform: translate3d(0, -64px, 0);
        top: 0;
        width: 20rem;
    }

    .month-picker>.rmp-container .rmp-popup.range {
        width: 40.6rem;
        padding: 0.6rem;
    }
}

.month-picker>.rmp-container .rmp-popup.show {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.month-picker>.rmp-container .rmp-popup:after {
    content: ' ';
    clear: both;
    display: table;
}

.month-picker>.rmp-container .rmp-popup .rmp-pad {
    position: relative;
}

@media screen and (min-width: 768px) {
    .month-picker>.rmp-container .rmp-popup .rmp-pad {
        box-sizing: border-box;
        float: left;
        width: 20rem;
    }

    .month-picker>.rmp-container .rmp-popup .rmp-pad:nth-of-type(2) {
        float: right;
    }
}

.month-picker>.rmp-container .rmp-popup .rmp-pad>div label {
    display: block;
    font-size: 1.4rem;
    text-align: center;
    line-height: 3.4rem;
}

.month-picker>.rmp-container .rmp-popup .rmp-pad>div label b {
    font-weight: normal;
    margin-right: 0.5em;
}

.month-picker>.rmp-container .rmp-popup .rmp-pad>div i {
    font-style: normal;
    text-align: center;
    width: 3.4rem;
    height: 3.4rem;
    line-height: 3.4rem;
    position: absolute;
    top: 0;
}

.month-picker>.rmp-container .rmp-popup .rmp-pad>div i.prev {
    left: 0;
}

.month-picker>.rmp-container .rmp-popup .rmp-pad>div i.next {
    right: 0;
}

.month-picker>.rmp-container .rmp-popup .rmp-pad ul,
.month-picker>.rmp-container .rmp-popup .rmp-pad li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.month-picker>.rmp-container .rmp-popup .rmp-pad ul {
    display: block;
    width: 100%;
}

.month-picker>.rmp-container .rmp-popup .rmp-pad ul:after {
    content: ' ';
    clear: both;
    display: table;
}

.month-picker>.rmp-container .rmp-popup .rmp-pad li {
    display: block;
    float: left;
    text-align: center;
    font-size: 1.15rem;
    border-radius: 3px;
    line-height: 3.3rem;
    box-sizing: border-box;
    padding: 0.05rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
}

@media screen and (max-width: 767px) {
    .month-picker>.rmp-container .rmp-popup .rmp-pad li {
        width: 25%;
    }
}

@media screen and (min-width: 768px) {
    .month-picker>.rmp-container .rmp-popup .rmp-pad li {
        width: 33.3333333333%;
    }
}

.month-picker>.rmp-container .rmp-popup .rmp-pad li.multiple {
    background-clip: content-box;
}

.month-picker>.rmp-container .rmp-popup .rmp-pad li.range {
    border-radius: 1px;
}

.month-picker>.rmp-container .rmp-popup.light {
    color: #666;
    background-color: rgba(255, 255, 255, 0.96);
}

@media screen and (max-width: 767px) {
    .month-picker>.rmp-container .rmp-popup.light {
        border-top: 1px solid #ccc;
        box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08);
    }

    .month-picker>.rmp-container .rmp-popup.light.range .rmp-pad {
        border-top: 1px solid rgba(204, 204, 204, 0.5);
    }

    .month-picker>.rmp-container .rmp-popup.light.range .rmp-pad:first-of-type {
        border-top: 0;
    }
}

@media screen and (min-width: 768px) {
    .month-picker>.rmp-container .rmp-popup.light {
        border: 1px solid #ccc;
        box-shadow: 0 1px 5px #ddd;
    }

    .month-picker>.rmp-container .rmp-popup.light.range .rmp-pad {
        background-color: rgba(238, 238, 238, 0.9);
    }
}

.month-picker>.rmp-container .rmp-popup.light .rmp-pad .rmp-btn {
    cursor: pointer;
    moz-user-select: -moz-none;
    -moz-user-select: none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media screen and (min-width: 768px) {
    .month-picker>.rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
        background-color: rgba(255, 227, 160, 0.59);
    }
}

.month-picker>.rmp-container .rmp-popup.light .rmp-pad .rmp-btn.select {
    background-color: #d3d3d3;
}

.month-picker>.rmp-container .rmp-popup.light .rmp-pad li.active,
.month-picker>.rmp-container .rmp-popup.light .rmp-pad li.active:hover {
    background-color: rgba(31, 42, 58, 0.73);
    color: white;
}

.month-picker>.rmp-container .rmp-popup.light .rmp-pad .disable,
.month-picker>.rmp-container .rmp-popup.light .rmp-pad .disable:hover,
.month-picker>.rmp-container .rmp-popup.light .rmp-pad li.disable,
.month-picker>.rmp-container .rmp-popup.light .rmp-pad li.disable:hover {
    background-color: transparent;
    color: #bbb;
    cursor: default;
}

.month-picker>.rmp-container .rmp-popup.dark {
    color: #fff;
    background-color: rgba(50, 50, 50, 0.96);
}

.unsdg-template .history_section_sdgs a:hover {
    color: #fff !important;
    cursor: pointer !important;
}

.tags-box-main {
    background: #F1F1F1;
    border-radius: 5px;
    padding: 15px;
}

.tag-filters ul {
    list-style: none;
    padding: 0;
    margin: 15px 0;
    display: flex;
    overflow: hidden;
    background: #dedede;
}

.tag-filters ul li {
    width: 100%;
}

.tag-filters ul li a {
    display: block;
    width: 100%;
    cursor: pointer;
    padding: 15px;
    font-size: 14px;
    border-radius: 5px;
    text-align: center;
}

.tag-filters ul li a:hover {
    background: #ccc;
}

.tag-filters ul li a.active {
    background: var(--sustainability-primary);
    color: #fff;
}

.tag-filters * {
    box-sizing: border-box;
}

.tags-affected .tags-box,
.tags-affected .tags-box .tag {
    background-color: transparent !important;
}

.round-number-item.have-objectives button {
    border: 2px solid #21ff21;
}

@media screen and (max-width: 767px) {
    .month-picker>.rmp-container .rmp-popup.dark.range .rmp-pad {
        border-top: 1px solid rgba(113, 113, 113, 0.41);
    }

    .month-picker>.rmp-container .rmp-popup.dark.range .rmp-pad:first-of-type {
        border-top: 0;
    }
}

@media screen and (min-width: 768px) {
    .month-picker>.rmp-container .rmp-popup.dark.range .rmp-pad {
        background-color: rgba(70, 70, 70, 0.9);
    }
}

.month-picker>.rmp-container .rmp-popup.dark .rmp-pad .rmp-btn {
    cursor: pointer;
    moz-user-select: -moz-none;
    -moz-user-select: none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media screen and (min-width: 768px) {
    .month-picker>.rmp-container .rmp-popup.dark .rmp-pad .rmp-btn:hover {
        background-color: rgba(255, 210, 96, 0.33);
    }
}

.month-picker>.rmp-container .rmp-popup.dark .rmp-pad .rmp-btn.select {
    background-color: #262828;
}

.month-picker>.rmp-container .rmp-popup.dark .rmp-pad li.active,
.month-picker>.rmp-container .rmp-popup.dark .rmp-pad li.active:hover {
    background-color: rgba(189, 211, 242, 0.7);
    color: #303030;
}

.month-picker>.rmp-container .rmp-popup.dark .rmp-pad .disable,
.month-picker>.rmp-container .rmp-popup.dark .rmp-pad .disable:hover,
.month-picker>.rmp-container .rmp-popup.dark .rmp-pad li.disable,
.month-picker>.rmp-container .rmp-popup.dark .rmp-pad li.disable:hover {
    background-color: transparent;
    color: #717171;
    cursor: default;
}

.month-picker>.box {
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    .month-picker>.box {
        box-sizing: border-box;
        line-height: 5rem;
        font-size: 1.32rem;
        color: #777777;
        text-align: right;
    }
}

@media screen and (min-width: 768px) {
    .month-picker>.box {
        border: 1px solid #e0e0e0;
        background-color: #f6f6f6;
        padding-left: 0.9rem;
        box-sizing: border-box;
        line-height: 3.2rem;
        font-size: 14px;
        color: #525252;
    }

    .month-picker>.box>label {
        font-size: 14px;
        color: #525252;
    }
}

.month-picker .tab.btn {
    font-size: 0;
}

.month-picker .tab.btn:before {
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-family: "icomoon";
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    font-size: 1.8rem;
    line-height: 3.4rem;
    text-align: center;
    width: 3.4rem;
}

.month-picker .tab.btn.prev:before {
    content: "";
}

.month-picker .tab.btn.next:before {
    content: "";
}

.years-panel {
    position: fixed;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: -10000px;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
}

.years-panel.table {
    display: table;
}

.years-panel.show {
    left: 0;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.years-panel.show>.overlay {
    left: 0;
    background-color: rgba(82, 82, 82, 0.49);
}

.years-panel>.overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
    top: 0;
    left: -10000px;
    opacity: 1;
    -webkit-tap-highlight-color: transparent;
    will-change: opacity;
    transform: translateZ(0);
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.years-panel .cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.years-panel .popup {
    display: inline-block;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
    margin: 0 auto;
    z-index: 10;
    font-size: 1.2rem;
    opacity: 0;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.81);
    padding: 1.8rem;
}

.years-panel .popup textarea {
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1.5rem;
    width: 20rem;
    line-height: 150%;
    padding: 1rem;
}

.years-panel .popup:after {
    content: ' ';
    clear: both;
    display: table;
}

.years-panel.show .popup {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.list-area {
    margin-top: 5rem;
}

.month-picker label {
    margin-bottom: 0;
}

.month-picker>.box {
    background: #fff;
    border-radius: 5px;
    line-height: initial;
    padding: 15px 10px;
}

.objective_main_showcase .main-unsdg-info {
    display: none;
}

.objective_main_showcase .new-design .left-side {
    flex: 1 1 100%;
}

.objective_main_showcase .new-design .right-side {
    flex: 1 1 100%;
}

.objective_main_showcase .tabs {
    margin-top: 0;
}

.objective_main_showcase .main-grid-box {
    border-right: 1px solid #ccc;
    padding-right: 25px;
}

.show-zoom-link {
    display: none;
}

.unshow-zoom-link {
    display: flex;
}

.sustainability-layout .navbar_main {
    justify-content: center;
}

.sustainability-layout .navbar_main .go_back_button {
    margin-left: 0;
    margin-right: auto;
}

.sustainability-layout .navbar_main .navbar_links.unshow-zoom-link {
    margin-left: auto;
    margin-right: 35px;
}

/* New Modals css  */
.NewUi_modals .MuiDialogTitle-root .modal-head {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.NewUi_modals .MuiDialogTitle-root .modal-head .MuiButtonBase-root {
    width: auto;
    margin: 0 0 0 0 !important;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0px, -50%);
    padding: 0;
}

.NewUi_modals .MuiDialogTitle-root .modal-head span.capital-case {
    color: #1B3548;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.NewUi_modals .MuiDialogContent-root {
    border: none !important;
}

.NewUi_modals .MuiDialogContent-root .tabs {
    margin: 0 0 0 0;
}

.NewUi_modals .MuiDialogContent-root .tabs a.tab-head-options {
    padding: 12px;
    background: transparent;
}

.NewUi_modals .MuiDialogContent-root .tabs a.tab-head-options.active {
    background: #009EDA;
}

.NewUi_modals section.scrollable-box {
    margin: 10px 0 0 0;
}

.NewUi_modals section.scrollable-box table.popup-table {
    border: 1px solid #D9D9D9;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.NewUi_modals section.scrollable-box table.popup-table thead tr th {
    text-align: left;
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid #D9D9D9;
    padding: 5px 10px;
}

.NewUi_modals section.scrollable-box table.popup-table tbody tr td {
    border-bottom: 1px solid #D9D9D9;
    background: #FFF;
    padding: 5px 10px;
}

.NewUi_modals section.scrollable-box table.popup-table tbody tr td {
    text-align: left;
}

.NewUi_modals section.scrollable-box table.popup-table tbody tr td .round-number-subject {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 5px;
}

.NewUi_modals section.scrollable-box table.popup-table tbody tr td .round-number-subject button.ant-btn {
    flex: 0 0 30px;
}

.NewUi_modals section.scrollable-box table.popup-table tbody tr:last-child td {
    border: none;
}

.NewUi_modals section.scrollable-box table.popup-table tbody tr td img.goal-images {
    width: 56px !important;
    height: 56px !important;
    max-width: 100% !important;
}

.NewUi_modals section.scrollable-box table.popup-table tbody tr td:first-child {
    border-left: 1px solid #D9D9D9;
    padding-left: 23px;
}

.NewUi_modals section.scrollable-box table.popup-table tbody tr td:last-child {
    border-right: 1px solid #D9D9D9;
}



@media screen and (min-width: 768px) {
    .list-area {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 767px) {
    .list-area>ul {
        border-bottom: 1px solid #e8e8e8;
    }

    .list-area>ul>li {
        position: relative;
        background-color: #fff;
        border-top: 1px solid #e8e8e8;
        padding: 0 1.6rem;
    }

    .list-area>ul>li:after {
        content: ' ';
        clear: both;
        display: table;
    }

    .list-area>ul>li>label {
        float: left;
        display: block;
        line-height: 5rem;
        color: #440f24;
        font-size: 1.12rem;
    }

    .list-area>ul>li>label b {
        font-size: 1.1em;
    }

    .list-area>ul>li>label span {
        position: absolute;
        display: block;
        font-size: 0.8rem;
        color: #bfbfbf;
        left: 1.6rem;
        top: 1.5em;
    }

    .list-area>ul>li>label span i {
        display: inline-block;
        font-style: normal;
    }

    .list-area>ul>li>label span i.sub-btn {
        display: none;
    }

    .list-area>ul>li .edit {
        margin-left: 9rem;
        line-height: 5rem;
    }
}

@media screen and (min-width: 768px) {
    .list-area>ul {
        padding: 1.8rem;
    }

    .list-area>ul>li {
        padding: 0.5rem 0 1.4rem;
        font-size: 1.3rem;
    }

    .list-area>ul>li:after {
        content: ' ';
        clear: both;
        display: table;
    }

    .list-area>ul>li>label {
        display: block;
        margin-right: 1rem;
        padding: 1px;
        color: #898989;
        font-size: 1.1rem;
        margin-bottom: 0.5em;
    }

    .list-area>ul>li>label b {
        display: block;
        font-size: 1.1em;
    }

    .list-area>ul>li>label span {
        display: block;
        font-size: 0.9em;
        color: #afafaf;
        margin-top: 0.4em;
    }

    .list-area>ul>li>label span i {
        display: inline-block;
        font-style: normal;
    }

    .list-area>ul>li>label span i.sub-btn {
        padding: 0.1rem 0.3rem;
        margin-left: 1.5rem;
        border-radius: 3px;
        background-color: #696969;
        color: white;
        font-size: 0.8em;
        cursor: pointer;
    }

    .list-area>ul>li .edit {
        padding: 1px;
        height: 3.3rem;
    }
}

/* -------------responsive----------------- */
@media only screen and (max-width: 1400px) {
    .sustainability-layout .navbar_links a.add-more-unsdg-tabs {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin: 10px 0 !important;
    }

    .toggle .sustainability-layout .navbar_main {
        padding: 0;
    }

    .toggle .sustainability-layout .navbar_main {
        grid-gap: 0 !important;
        column-gap: 0 !important;
        width: 250px !important;
        overflow: hidden;
    }

    /* .new-design .tabs {
        display: block;
    } */

    .new-design .main-discrimination-box {
        padding: 20px;
    }

    .sustainability-layout .navbar_links_tabs {
        flex-direction: column;
    }

    .show-zoom-link {
        display: flex;
    }

    .unshow-zoom-link {
        display: none !important;
    }

    .sustainbility-menu-dropdown-main {
        width: 100%;
        text-align: left;
    }

    .sustainbility-menu-dropdown-list {
        background: #ffffff;
        z-index: 3;
        padding: 10px;
    }

    .sustainability-layout .navbar_links a,
    .sustainability-layout .navbar_links button {
        text-align: left;
        width: 100%;
        margin: 0 0 !important;
        justify-content: left;
    }

    .sustainability-layout .navbar_main {
        flex-direction: column;
        width: 165px;
        height: 100vh;
        justify-content: flex-start;
        left: -100%;
    }

    .sustainability-layout .toggle .navbar_main {
        left: 0;
    }

    .sustainability-layout .toggle_button {
        display: flex;
        justify-content: space-between;
        background: white;
        position: fixed;
        right: 0;
        top: 0;
        background: white;
        width: 100%;
        padding: 10px 0;
        z-index: 99;
    }

    .sustainability-layout .history_link {
        display: flex;
        flex-direction: column;
    }

    .sustainability-layout .navbar_links {
        display: flex;
        flex-direction: column;
        margin-top: 79px;
    }

    .sustainability-layout .go_back_button {
        display: none;
    }

    .sustainability-layout .outlet_here {
        margin-top: 25px;
        padding: 17px 0;
    }

    .sustainability-layout .navbar_links a,
    .sustainability-layout .history_link a {
        padding: 20px 0 4px 0;
        margin: 1px 1px;
    }

    .sustainability-layout .divider {
        display: none;
    }

    .sustainability-layout .navbar_links,
    .sustainability-layout .navbar_links_tabs {
        width: 100%;
        display: block;
    }

    .sustainability-layout .navbar_links a,
    .sustainability-layout .history_link a,
    .sustainability-layout .navbar_links button {
        padding: 10px 15px !important;
        display: block;
        font-size: 13px !important;
    }

    .sustainability-layout .navbar_links button.sustainbility-menu-dropdown {
        display: flex;
        padding: 6.42px 15px !important;
    }

    .sustainability-layout .navbar_links a.divider,
    .sustainability-layout .history_link a.divider,
    .sustainability-layout .navbar_links button.divider {
        display: none;
    }

    .sustainbility-menu-dropdown-main button.fixed {
        display: none;
    }

    .sustainbility-menu-dropdown-main .sustainbility-menu-dropdown-list {
        top: 0;
        border-bottom: 0;
        padding: 0;
        margin-bottom: -5px;
        position: relative;
    }

    button.right-unsdg-btn {
        height: 36px !important;
        padding: 2px !important;
    }

    .sustainability-layout .toggle_button img {
        height: 30px;
        width: auto;
    }

}


@media only screen and (max-width:1200px) {
    .new-design .main-discrimination-box {
        padding: 20px;
    }

    .new-design .right-side-box {
        justify-content: center;
    }
}

@media only screen and (max-width: 767px) {

    .cms-content-area {
        width: calc(100% - 60px);
    }

    .cms-upper-head-link {
        display: none;
    }

    .cms-upper-head {
        width: 100%;
        margin: 0 !important;
        justify-content: center !important;
    }

    .cms-upper-head img {
        margin-left: 0 !important;
    }

    .event-box-main {
        display: block;
    }

    .event-box-title {
        padding-right: 0;
        width: 100%;
    }

    .event-box-middle {
        margin: 10px 0;
    }

    .event-box-short-desc {
        justify-content: flex-start !important;
        text-align: left;
        margin-left: 0;
        margin-right: auto;
        padding-left: 0;
        width: 100%;
    }

    .unsdg-template .main_sdgs_div .header_sdgs {
        flex-direction: column;
        row-gap: 25px;
        column-gap: 0;
    }

    .unsdg-template .after_line {
        margin: 0;
        text-align: center;
    }

    .unsdg-template .after_line::after {
        display: none;
    }

    .unsdg-template .main_sdgs_div .header_sdgs div {
        text-align: center;
    }

    .unsdg-template h2 {
        font-size: 30px;
    }

    .unsdg-template .main_card_div {
        flex-basis: 100%;
    }

    .unsdg-template .video_section_sdgs {
        flex-direction: column;
    }

    .unsdg-template .video_div_sdgs iframe,
    .unsdg-template .video_div_sdgs {
        width: 100%;
    }

    .unsdg-template .history_section_sdgs h2 {
        font-size: 32px !important;
        text-align: center;
    }

    .unsdg-template .accordion_section_sdgs {
        width: 100%;
        margin-top: 25px;
    }

    .new-design .round-number {
        min-width: 22px;
        height: 22px;
    }

    .new-design .grid-item {
        width: 114px;
        height: 114px;
    }

    .new-design .grid-container {
        grid-template-columns: repeat(4, 144px);
    }

    .new-design .col-50 {
        flex: 1 1 100%;
    }
}

@media screen and (max-width:574px) {
    .new-design .right-side-panel {
        width: 320px;
    }

    .new-design .navy-table table tbody tr td:first-child {
        padding-left: 20px;
        font-size: 12px;
    }

    .new-design .navy-table table thead tr th:first-child {
        padding-left: 20px;
        font-size: 14px;
    }

    .new-design .tabs>input {
        font-size: 12px;
    }

    .new-design .right-side-box {
        justify-content: end;
    }

    .new-design .grid-container {
        grid-template-columns: repeat(4, 90px);
    }

    .new-design .grid-item {
        padding: 8px;
        width: 74px;
        height: 74px;
    }

    .new-design .up-side .right-bar {
        font-size: 10px;
    }

    .new-design .down-side .right-bar {
        font-size: 10px;
    }

    .new-design .chart-names p {
        margin: 0;
        font-size: 12px;
    }

    .new-design .white-btn,
    .new-design .navy-btn {
        padding: 10px 8px;
        min-width: 100px;
        font-size: 12px;
    }

}

@media screen and (max-width:365px) {}